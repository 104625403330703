import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubscribeService } from '../../../../services/subscribe.service';
import * as _ from 'lodash';
import { IChips } from './chips/filter-chips.component';
import { AppService } from '../../../../services/app.service';

export interface IFilterData {
  label: string;
  value: string | number;
  selected?: boolean;
  matchWithKey: string;
}

export interface ISearch {
  background?: string;
  placeholder: string;
}

export interface ISelect {
  placeholder: string;
  name: string;
  data: IFilterData[];
  isOpened?: boolean;
  label?: string;
  multiple?: boolean;
}

export interface IFilters {
  search: {
    placeholder: string;
  };
  chips: IChips;
  select: ISelect[];
}

export interface IFiltersActive {
  search: string;
  chips: IFilterData[];
  select: { [s: string]: IFilterData[] };
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnDestroy {
  public searchModel: string;
  public showSearchReset: boolean = false;
  @Input() search: ISearch = null;
  @Input() chips: IChips = null;
  @Input() selects: ISelect[] = null;
  @Output() filtersChanged: EventEmitter<IFiltersActive> = new EventEmitter();
  private throttleSearch: Subject<string> = new Subject();
  public filtersPanelOpen: boolean = false;
  public showCategoriesChips: boolean = false;
  public isMobile: boolean = false;

  constructor(
    private subscribeService: SubscribeService,
    private appService: AppService
  ) {
    this.isMobile = this.appService.deviceIsMobile();
    this.filtersPanelOpen = this.isMobile;
    this.showCategoriesChips = this.isMobile;
    this.subscribeService.addSubscribe(
      this.throttleSearch.pipe(debounceTime(200)).subscribe(searchTextValue => {
        this.fireChangeEvent();
      })
    );
  }

  static doFilter(originalList: any[], filtersActive: IFiltersActive) {
    const _tmpFilteredList = originalList.filter(item => {
      let matchedItem: boolean = true;
      if (filtersActive.search !== undefined) {
        const matchName = item.storeName
          .toLowerCase()
          .search(filtersActive.search);
        matchedItem = matchName >= 0;
      }
      if (matchedItem !== null && matchedItem === false) {
        return matchedItem;
      }
      if (filtersActive.chips && filtersActive.chips.length > 0) {
        let matchChips = false;
        filtersActive.chips.forEach(chip => {
          const categoryName = item.storeCategories.toLowerCase();
          // categoryName = categoryName.replace(/ /g, '-'); // remove whitespace
          // matchedItem = categoryName.search(chip.value.toLowerCase()) >= 0;
          if (typeof chip.value === 'string') {
            if (categoryName.search(chip.value.toLowerCase()) >= 0) {
              matchChips = true;
            }
          }
        });
        matchedItem = matchChips;
      }
      if (matchedItem !== null && matchedItem === false) {
        return matchedItem;
      }
      if (
        filtersActive.select &&
        Object.keys(filtersActive.select).length > 0
      ) {
        let matchedItemInSelect = true;
        for (const selectKey of Object.keys(filtersActive.select)) {
          if (filtersActive.select[selectKey].length > 0) {
            for (const selection of filtersActive.select[selectKey]) {
              const value = selection.value;
              const checkValue = () => {
                if (typeof item[selection.matchWithKey] === 'number') {
                  return item[selection.matchWithKey] === value;
                } else {
                  return item[selection.matchWithKey].indexOf(value) >= 0;
                }
              };
              if (selection.matchWithKey === 'storeTerminal') {
                if (value === 'ANY_TERMINALS') {
                  matchedItemInSelect = true;
                } else {
                  matchedItemInSelect = checkValue();
                }
              }
              if (selection.matchWithKey === 'afterSecurityCheck') {
                if (matchedItemInSelect === true) {
                  if (value === 5) {
                    matchedItemInSelect = true;
                  } else {
                    matchedItemInSelect = checkValue();
                  }
                }
              }
            }
            matchedItem = matchedItemInSelect;
          }
        }
      }
      return matchedItem;
    });
    return _tmpFilteredList;
  }

  public hasFilters(): boolean {
    return !_.isNil(this.chips) && !_.isNil(this.selects) && this.selects.length > 0;
  }

  toogleFiltersPanel(): void {
    this.filtersPanelOpen = !this.filtersPanelOpen;
    this.showCategoriesChips = false;
  }

  ngOnDestroy() {
    this.subscribeService.unSubscribe();
  }

  /**
   * On input key-up
   */
  searchKeyUp(): void {
    this.showSearchReset = !_.isNil(this.searchModel) && this.searchModel.length > 0;
    this.throttleSearch.next();
  }

  /**
   * Clear input search
   */
  searchReset(): void {
    this.searchModel = '';
    this.showSearchReset = false;
    this.fireChangeEvent();
  }

  getActiveLabel(select: ISelect): string {
    const foundedItem = select.data.find(item => item.selected === true);
    if (!_.isNil(foundedItem)) {
      return foundedItem.label;
    }
    return select.placeholder;
  }

  /**
   * Click sul singolo elemento
   *
   * @param filterValue - Elemento IFilterData
   */
  filterValueClicked(filterValue: IFilterData, skipSet: boolean = false): void {
    console.log('filterValueClicked', filterValue);
    this.selectCloseAll();
    if (skipSet === false) {
      if (typeof filterValue.selected === 'boolean') {
        filterValue.selected = !filterValue.selected;
      } else {
        filterValue.selected = true;
      }
    }
    this.fireChangeEvent();
  }

  /**
   * Click sul singolo elemento
   *
   * @param select - Elemento select
   */
  selectClicked(select: ISelect): void {
    this.selectCloseAll(select);
    if (typeof select.isOpened === 'boolean') {
      select.isOpened = !select.isOpened;
    } else {
      select.isOpened = true;
    }
  }

  /**
   * Click sull'elemento della select
   *
   * @param valueClicked
   * @param select
   */
  selectValueClicked(valueClicked: IFilterData, select: ISelect): void {
    if (select.multiple !== true) {
      select.data.forEach(item => {
        item.selected = false;
      });
    }
    select.label = valueClicked.label;
    this.filterValueClicked(valueClicked);
  }

  /**
   * Chiude il pannello di tutte le select
   *
   * @param clickedSelect - skippa la chiusura del pannello per la select "passata"
   */
  selectCloseAll(clickedSelect: ISelect = null): void {
    this.selects.forEach(select => {
      if (clickedSelect === null || select.name !== clickedSelect.name) {
        select.isOpened = false;
      }
    });
  }

  fireChangeEvent(): void {
    const filtersActive: IFiltersActive = {
      search: this.searchModel,
      chips: null,
      select: null
    };
    if (this.chips) {
      const selectedChips = this.chips.data.filter(chip => {
        return chip.selected;
      });
      filtersActive.chips = selectedChips;
    }
    if (this.selects) {
      const selectedSelect = {};
      this.selects.forEach(select => {
        const selection = select.data.filter(data => {
          return data.selected;
        });
        selectedSelect[select.name] = selection;
      });
      filtersActive.select = selectedSelect;
    }
    this.filtersChanged.emit(filtersActive);
  }
}
