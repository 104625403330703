<div class="tutorials" [@fadeInOut]="animateContent ? 'show' : 'hide'"
  (@fadeInOut.done)="animationCloseTutorial($event)" [hidden]="!canShow">
  <div class="tutorial__step tutorial__step--{{ step.name }}" *ngFor="let step of stepsTutorial; let ind = index"
    [@fadeInOut]="animateStep ? 'show' : 'hide'" (@fadeInOut.done)="animationComplete($event)">
    <div [hidden]="!showStep(ind)">
      <i class="tutorial__close fas fa-times" (click)="closeTutorial()" *ngIf="showExit()"></i>
      <div class="tutorial__content" [ngStyle]="contentStyles">
        <span class="content__title">{{ step.title | translate }}</span>
        <span class="content__description">{{ step.description | translate }}</span>
        <span class="content__image" *ngIf="showImage()">
          <img src="./assets/img/tutorial/{{step.arrow}}" />
        </span>
      </div>
      <span class="tutorial__spot" [ngStyle]="spotStyles"></span>
    </div>
  </div>
  <div class="tutorial__footer">
    <div class="footer__left">
      <span class="skip_tutorial" (click)="closeTutorial()" *ngIf="showSkipNext()">{{ 'SKIP' | translate }}</span>
    </div>
    <div class="footer__right">
      <span class="arrow_tutorial" (click)="prevTutorial()" *ngIf="showPrev()"><i class="fas fa-arrow-left"></i></span>
      <span class="arrow_tutorial" (click)="nextTutorial()" *ngIf="showSkipNext()"><i
          class="fas fa-arrow-right"></i></span>
      <span class="finish_tutorial" (click)="closeTutorial()" *ngIf="showFinish()">{{ 'FINISH' | translate }}</span>
    </div>
  </div>
</div>
