import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { UserTypeService } from './user-type.service';
import { Data, Router } from '@angular/router';
import * as _ from 'lodash';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor(
    private router: Router,
    private appService: AppService,
    private title: Title,
    private userTypeService: UserTypeService
  ) {}

  public setTitle(newTitle: string) {
    if (this.userTypeService.getUserSource() === 'SOSTRAVEL') {
      this.title.setTitle(newTitle + ' | sostravel');
    } else if (this.userTypeService.getUserSource() === 'FLIO') {
      this.title.setTitle(newTitle + ' | FLIO');
    }
  }

  public autoResolve(routeData?: Data): void {
    // const url: string = this.router.url;
    let title: string = '';

    if (this.appService.getError() === null) {
      if (!_.isNil(routeData.detail)) {
        title += routeData.detail.name + ' - ';
      }
      if (!_.isNil(routeData.service)) {
        title += routeData.service.name + ' - ';
      }
      if (!_.isNil(routeData.airport)) {
        title += routeData.airport.name;
      } else if (!_.isNil(routeData.airline)) {
        title += routeData.airline.name;
      }
    } else {
      title = 'Error';
    }

    this.setTitle(title);
  }
}
