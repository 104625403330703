<!-- ***********************************************************************
  *
  * SOSTRAVEL.com S.p.A CONFIDENTIAL
  * __________________
  *
  * [2016] - [2018] SOSTRAVEL.com S.p.A
  * All Rights Reserved.
  *
  * NOTICE:  All information contained herein is, and remains
  * the property of SOSTRAVEL.com S.p.A and its suppliers,
  * if any.  The intellectual and technical concepts contained
  * herein are proprietary to SOSTRAVEL.com S.p.A
  * and are protected by trade secret or copyright law.
  * Dissemination of this information or reproduction of this material
  * is strictly forbidden unless prior written permission is obtained
 * from SOSTRAVEL.com S.p.A.
 *
 * __________________
 *
 * Secured    on (last update) :
 * Documented on (last update) :
 *
 ************************************************************************
 */-->

<app-eta-tutorial></app-eta-tutorial>
<div>
  <div id="eta" *ngIf="appService.getError() === null" appDeviceType>
    <div class="google_map">
      <div id="google_map__container"></div>
      <div class="google_map__overlay">
        <div class="google_map__type" (click)="changeMapType()" *ngIf="googleMapsLoaded">
          <ng-container *ngIf="isMapType(google.maps.MapTypeId.ROADMAP)">
            <img src="./assets/img/map-eta/map_satellite.png" class="Map Satellite" />
          </ng-container>
          <ng-container *ngIf="isMapType(google.maps.MapTypeId.SATELLITE)">
            <img src="./assets/img/map-eta/map_traffic.png" class="Map Traffic" />
          </ng-container>
        </div>
        <div class="google_map__flight_info" *ngIf="flightProgressInfo !== null" (click)="swapFlightProgressInfo()">
                <span *ngIf="tickIntervalViewObj.flightInfo === 'duration'">{{ 'FLIGHT_DURATION' | translate }}
                  {{ flightProgressInfo.flightDuration }}</span>
          <span *ngIf="tickIntervalViewObj.flightInfo === 'in_flight'">{{ 'IN_FLIGHT' | translate }}
            {{ flightProgressInfo.inFlightTime }}</span>
          <span *ngIf="tickIntervalViewObj.flightInfo === 'landing_in'">{{ 'LANDING_IN' | translate }}
            {{ flightProgressInfo.landingInTime }}</span>
        </div>
      </div>
    </div>
    <div class="travel_detail" #travel_detail>
      <div class="travel_airports travel_airports--count-{{
                getAllAirportInItinerary().length
              }}">
        <ng-template ngFor let-airportFsCode [ngForOf]="getAllAirportInItinerary()" let-index="index"
                     let-count="count">
          <div class="travel_airports__label"
               [class.travel_airports__label--landed]="index < flightActiveIndex || index > flightActiveIndex + 1"
               (click)="navigateToAirport(airportFsCode)">
            {{ airportFsCode }}
          </div>
          <div class="travel_airports__route" *ngIf="index === flightActiveIndex">
            <span class="flight-active-status">{{ flightActiveStatus }}</span>
            <span class="route__progress" [style.width.%]="_flightProgressValue"></span>
            <img [style.left.%]="_flightProgressValue" src="./assets/img/icon-eta/plane_icon.png"
                 alt="aereo stilizzato" />
          </div>
          <img *ngIf="index !== flightActiveIndex && index < count - 1" src="./assets/img/icon-eta/plane_icon.png"
               alt="aereo stilizzato" />
        </ng-template>
      </div>
      <div class="travel_flights container-fluid">
        <div class="row row--itinerary">
          <div class="col-12">
            <ng-template ngFor let-flight [ngForOf]="flights" let-index="index" let-count="count" track-by="trackByCard">
              <app-flight-card [flight]="flight" [canCollapse]="index + 1 !== count && flight.isLanded()">
              </app-flight-card>
              <div class="travel_connection_time" *ngIf="flight.connectionFlight > 0">
                {{ 'CONNECTION_TIME' | translate }}:
                <span>{{ getConnectionTime(index) }}</span>
              </div>
              <div class="travel_pick_bag" *ngIf="index + 1 === count && flight.isLanded()">
                <span>{{ 'BAG_AT_CAROUSEL' | translate }}:</span>
                <span class="carousel_number">
                  <i class="etf-bagagge-carousel"></i>
                  {{ flight.getCarouselForBag() }}
                </span>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
