import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { AirportModel } from './modules/airport/models/airport-model';
import { filter } from 'rxjs/operators';
import { AirlineModel } from './modules/airline/models/airline.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingService {
  public clearHistory: string = null;
  private history = [];

  constructor(
    private router: Router,
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        // console.log('@HIS - clearHistory composta da:', this.clearHistory);
        // console.log('@HIS - urlAfterRedirects:', urlAfterRedirects);

        this.history.forEach(history => {
          // console.log('@HIS - history single:', history);

          if (history.indexOf(this.clearHistory) >= 0) {
            // console.log('@HIS - rimuovo history', this.history);
            this.history.splice(history.indexOf(this.clearHistory), 1);
          }
          this.clearHistory = null;
        });

        this.history = _.uniq([...this.history, urlAfterRedirects]);
        // console.log('@HIS - history composta da:', this.history);
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public havePreviousUrl(): boolean {
    const prevUrl = this.getPreviousUrl(false);
    return prevUrl !== undefined;
  }

  public getPreviousUrl(remove: boolean = true): string {
    const prevUrl = this.history[this.history.length - 2];
    if (remove) {
      this.history = this.history.slice(0, this.history.length - 2);
    }
    return prevUrl;
  }

  public goToPreviousUrl(): void {
    const prevUrl = this.getPreviousUrl();
    this.history = this.history.slice(0, this.history.length - 2);
    this.router.navigate([prevUrl]);
  }

  public getRoutesData(): Data {
    const root = this.router.routerState.snapshot.root;
    return this.lastChild(root).data;
  }

  public getRouteData(keyName: string): any {
    return this.getRoutesData()[keyName];
  }

  public getAirportCode(): string {
    const airportData = this.getRouteData('airport');
    if (airportData) {
      return new AirportModel(airportData).getFsCode();
    }
    return null;
  }

  public getAirlineCode(): string {
    const airlineData = this.getRouteData('airline');
    if (airlineData) {
      return new AirlineModel(airlineData).fsCode;
    }
    return null;
  }

  private lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.lastChild(route.firstChild);
    } else {
      return route;
    }
  }
}
