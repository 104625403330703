import { Directive, HostBinding, HostListener, OnInit } from '@angular/core';
import { AppService } from '../../../services/app.service';

@Directive({
  selector: '[appDeviceType]'
})
export class DeviceTypeDirective implements OnInit {

  @HostBinding('attr.device') attrDevice: string;

  constructor(private appService: AppService) {
  }

  //
  private get deviceType(): string {
    return this.appService.deviceIsDesktop() ? 'desktop' : 'mobile';
  }

  @HostListener('window:resize')
  onResize() {
    this.attrDevice = this.deviceType;
  }

  ngOnInit(): void {
    this.attrDevice = this.deviceType;
  }

}
