<div class="filters" appDeviceType>

  <div
    class="w-100"
    [ngStyle]="{
      'background-color':
        search && search.background ? search.background : 'transparent'
    }"
  >
    <div class="container-custom">
      <div class="row align-items-center">
        <div class="col-12">
          <div class="filters__main">
            <div class="form-group" *ngIf="search">
              <div class="input-group">
                <div class="input-group-addon input-group-addon-before">
                  <i class="fas fa-search"></i>
                </div>
                <input
                  type="search"
                  class="form-control clearable"
                  name="search"
                  maxlength="10"
                  placeholder="{{ search.placeholder | translate }}"
                  [(ngModel)]="searchModel"
                  (keyup)="searchKeyUp()"
                />
                <div
                  class="input-group-addon input-group-addon-after"
                  *ngIf="showSearchReset"
                >
                  <i class="fas fa-times" (click)="searchReset()"></i>
                </div>
              </div>
            </div>
            <span
              class="filters__toggle"
              *ngIf="isMobile === false && hasFilters()"
              (click)="toogleFiltersPanel()"
              [class.filters__toggle--open]="filtersPanelOpen"
            >
              <i class="etf-filter"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="filters__panel filters__panel--select" *ngIf="hasFilters()" [hidden]="!filtersPanelOpen">
    <div class="panel__wrapper">
      <div class="container-custom">
        <div class="row">
          <div class="col-12 filters_label_wrapper">
            <div class="filter_select" *ngFor="let select of selects">
              <span mat-button [matMenuTriggerFor]="beforeMenu">
                {{ getActiveLabel(select) | translate }}
              </span>
              <mat-menu #beforeMenu="matMenu" class="mat-menu-panel--filter mat-menu-panel--filter-{{ isMobile ? 'mobile' : 'desktop' }}" xPosition="before">
                <button
                  mat-menu-item
                  class="mat-menu-item--filter"
                  *ngFor="let options of select.data"
                  (click)="selectValueClicked(options, select)"
                  [class.mat-menu-item--selected]="options.selected"
                >
                  {{ options.label | translate }}
                </button>
              </mat-menu>
            </div>
            <div class="filter_select" *ngIf="isMobile === false && chips" (click)="showCategoriesChips = !showCategoriesChips">
              <span class="mat-menu-trigger" [attr.aria-expanded]="showCategoriesChips">
                {{ 'CATEGORIES' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="filters__panel filters__panel--chips" *ngIf="hasFilters() && filtersPanelOpen && showCategoriesChips && chips">
    <div class="panel__wrapper pb-0">
      <div class="container-custom">
        <div class="row">
          <div class="col-12 col-mobile-px-0">
            <app-filter-chips
              [chips]="chips"
              (change)="filterValueClicked($event, true)"
            ></app-filter-chips>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
