import { Component, OnInit } from '@angular/core';
import { UserTypeService } from '../../services/user-type.service';
import { LanguageService } from '../../services/language-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: number = new Date().getFullYear();
  language: string;
  userSource: string;

  constructor(
    public userService: UserTypeService,
    private languageService: LanguageService
  ) {
    languageService.idiomaChange.subscribe(() => {
      this.ngOnInit();
    });

    userService.userSourceSbj.subscribe(userSource => {
      this.userSource = userSource;
    });
  }

  ngOnInit() {
    if (this.languageService.getLanguageIdioma() !== 'it') {
      this.language = 'EN';
    } else {
      this.language = 'IT';
    }
  }

  isFlio(): boolean {
    return this.userSource === 'FLIO';
  }
}
