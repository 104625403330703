export enum ServicePositionInApp {
  HOME,
  AIRPORT,
  ALL
}

export enum EInterfaceType {
  AppListOfEntry = 'APP_LIST_OF_ENTRY',
  AppSingleEntry = 'APP_SINGLE_ENTRY',
  WebURLRedirect = 'WEB_URL_REDIRECT',
  AppNativeInterface = 'APP_NATIVE_INTERFACE',
}

export enum EInterfaceCode {
  Timetable = 'TTABLE',
  VipLounge = 'VIPLOU',
  Covid = 'COVID19'
}

export enum InterfaceType {
  APP_NATIVE_INTERFACE,
  WEB_URL_REDIRECT,
  APP_LIST_OF_ENTRY,
  APP_SINGLE_ENTRY
}

export enum ServiceCellUI {
  STORE_CELL = 'STORE_CELL',
  SERVICE_LOGO = 'SERVICE_LOGO',
  SERVICE_TITLE = 'SERVICE_TITLE',
  SERVICE_ONLY_LOCATION = 'SERVICE_ONLY_LOCATION',
  SERVICE_PICTURE = 'SERVICE_PICTURE'
}

export enum ServiceTypeStatus {
  NOT_AVAILABLE,
  ACTIVE,
  WORK_IN_PROGRESS
}
