import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appUiImageLoader]'
})
export class UiImageLoaderDirective implements OnChanges {

  @Input('src') src: string;

  @Input('loader') public loader: string = './assets/img/loader.gif';
  @Input('onErrorSrc') public onErrorSrc: string = './assets/img/placeholder.png';
  private transparent: string = './assets/img/transparent_1x1.png';

  private restoreStyles: { [key: string]: string } = {};

  constructor(private renderer: Renderer2, private el: ElementRef) {
  }

  get element(): HTMLElement {
    return this.el.nativeElement;
  }

  get elementType(): string {
    return this.element.tagName;
  }

  get isImage(): boolean {
    return this.elementType === 'IMG';
  }

  ngOnChanges(): void {
    if (this.isImage === false) {
      this.element.style.backgroundImage = 'url("' + this.loader + '")';
      this.restoreStyles.backgroundSize = window.getComputedStyle(this.element).backgroundSize;
      this.element.style.backgroundSize = 'auto';

      this.fetchImage(this.src);
    } else {
      this.element.style.backgroundImage = 'url("' + this.loader + '")';
      this.element.style.backgroundRepeat = 'no-repeat';
      this.element.style.backgroundPosition = 'center center';
      this.restoreStyles = {
        'minWidth': window.getComputedStyle(this.element).minWidth,
        'minHeight': window.getComputedStyle(this.element).minHeight
      };
      this.element.style.minWidth = '25px';
      this.element.style.minHeight = '25px';
      this.element.style.backgroundSize = '25px 25px';
      this.renderer.setAttribute(this.element, 'src', this.transparent);
    }
    console.groupEnd();
  }

  @HostListener('load') onLoad() {
    this.setImage(this.src);
  }

  @HostListener('error') onError() {
    this.setImage(this.onErrorSrc, false);
  }

  private setImage(image: string, restore: boolean = true) {
    // console.log('immagine caricata!');
    if (this.isImage === false) {
      // console.log('immagine è: ', image);
      this.element.style.backgroundImage = 'url("' + image + '")';
      if (restore === false) {
        this.element.style.backgroundSize = 'auto';
      }
    } else {
      // console.log('immagine è: ', this.src);
      if (this.element.getAttribute('src') !== this.src) {
        this.element.style.background = 'none';
        this.renderer.setAttribute(this.element, 'src', this.src);
      }
    }
    if (restore === true) {
      for (const styleName of Object.keys(this.restoreStyles)) {
        this.element.style[styleName] = this.restoreStyles[styleName];
      }
    }
  }

  private fetchImage(url: string): void {
    const image = document.createElement('img');
    image.src = url;
    image.style.position = 'absolute';
    image.style.height = '0';
    image.style.width = '0';
    image.style.opacity = '0';
    image.style.visibility = 'hidden';
    image.onload = () => {
      this.onLoad();
    };
    image.onerror = () => {
      this.onError();
    };
    this.renderer.appendChild(this.element, image);
    // console.log('inizio caricamento immagine:', url);
  }

}
