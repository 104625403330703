/**
 ***********************************************************************
 *
 * SOSTRAVEL.com S.p.A CONFIDENTIAL
 * __________________
 *
 * [2016] - [2018] SOSTRAVEL.com S.p.A
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of SOSTRAVEL.com S.p.A and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to SOSTRAVEL.com S.p.A
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from SOSTRAVEL.com S.p.A.
 *
 * __________________
 *

 * Secured    on (last update) :
 * Documented on (last update) :
 *
 ************************************************************************
 */

import { Injectable } from '@angular/core';
import { LanguageModel } from '../model/language';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { UrlParams } from './url-params';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  idiomaChange: Subject<string> = new Subject<string>();
  private idioma: string = 'en';
  // public idiomaObs = this.idioma.asObservable();
  private languages: LanguageModel[] = [
    {idioma: 'it', idiomaDescription: 'Italiano'},
    {idioma: 'en', idiomaDescription: 'English'},
    {idioma: 'fr', idiomaDescription: 'Français'},
    {idioma: 'de', idiomaDescription: 'Deutsch'},
    {idioma: 'pl', idiomaDescription: 'Polski'},
    {idioma: 'es', idiomaDescription: 'Español'},
    {idioma: 'pt', idiomaDescription: 'Português'},
    {idioma: 'ru', idiomaDescription: 'Pусский'},
    {idioma: 'ar', idiomaDescription: 'العربية'},
    {idioma: 'zh', idiomaDescription: '中国'},
    {idioma: 'ja', idiomaDescription: '日本の'}
  ];

  constructor(private translate: TranslateService, private activatedRoute: ActivatedRoute) {

    if (UrlParams.hasParams()) {
      const param = UrlParams.get('lang');
      if (!_.isNil(param)) {
        this.idioma = this.getVerifiedIdioma(param);
      }
    } else {
      if (sessionStorage.getItem('idiomaSelected')) {
        this.idioma = sessionStorage.getItem('idiomaSelected');
      } else if (translate.getBrowserLang() !== undefined) {
        const newIdioma: string = translate.getBrowserLang();
        this.idioma = this.getVerifiedIdioma(newIdioma);
      }
    }
  }

  setIdioma(idioma: string): void {
    sessionStorage.setItem('idiomaSelected', idioma);
    this.idioma = idioma;
    this.idiomaChange.next(idioma);
  }

  getLanguages(): LanguageModel[] {
    return this.languages;
  }

  getLanguage(): LanguageModel {
    return this.languages.find(language => language.idioma === this.idioma);
  }

  getLanguageIdioma(): string {
    // console.log('@LanguageService - getLanguageIdioma:', this.getLanguage().idioma);
    return this.getLanguage().idioma;
  }

  getLanguageCountryCode(): string {
    const idioma: string = this.getLanguageIdioma();
    let code = idioma;
    switch (idioma) {
      case 'en':
        code = 'gb';
        break;
      case 'zh':
        code = 'cn';
        break;
      case 'ar':
        code = 'sa';
        break;
      case 'ja':
        code = 'jp';
        break;
    }
    return code;
  }

  getLanguageDescription(): string {
    return this.getLanguage().idiomaDescription;
  }

  private getVerifiedIdioma(idioma: string): string {
    const foundedIdioma = this.languages.find(
      language => language.idioma === idioma
    );
    if (_.isNil(foundedIdioma)) {
      return 'en';
    } else {
      return idioma;
    }
  }
}
