import { Component, Inject } from '@angular/core';
import { LanguageModel } from 'src/app/model/language';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-select-language',
  templateUrl: './dialog-select-language.component.html',
  styleUrls: ['./dialog-select-language.component.scss']
})
export class DialogSelectLanguageComponent {
  avaibleLanguages: LanguageModel[] = [];

  constructor(
    private _dialogRef: MatDialogRef<DialogSelectLanguageComponent>,
    @Inject(MAT_DIALOG_DATA) private languages: LanguageModel[],
    private translate: TranslateService
  ) {
    this.avaibleLanguages = languages.filter(
      item => item.idioma !== this.translate.currentLang
    );
  }

  openLink(event: MouseEvent): void {
    event.preventDefault();
  }

  switchL(idioma: string) {
    sessionStorage.setItem('idiomaSelected', idioma);
    this.translate.use(idioma);
    const selectedLanguage = this.languages.find(
      language => language.idioma === idioma
    );
    this._dialogRef.close(selectedLanguage);
  }
}
