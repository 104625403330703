<div class="itinerary__col_detail">
  <span class="itinerary__terminal" *ngIf="terminal !== ''">
    <span class="terminal">{{ 'TERMINAL' | translate | uppercase }}</span>
    <span>{{ terminal }}</span>
  </span>
  <span class="itinerary__gate">
    <span class="gate">{{ 'GATE' | translate | uppercase }}</span>
    <span>{{ gate }}</span>
  </span>
</div>
