import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () {
    };
    window.console.group = function () {
    };
    window.console.groupEnd = function () {
    };
    window.console.info = function () {
    };
    // window.console.error = function() {};
  }
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));

document.addEventListener('DOMContentLoaded', () => {
  document.getElementsByTagName('html')[0].setAttribute('data-eta-version', environment.sentry.release);
});

// https://medium.com/@lenseg1/loading-different-angular-modules-or-components-on-routes-with-same-path-2bb9ba4b6566
// export let appInjector: Injector;
// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then(componentRef => {
//     appInjector = componentRef.injector;
//   });
