import {
  Directive,
  OnChanges,
  Input,
  HostBinding,
  ElementRef,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[smoothHeight]',
})
export class SmoothHeightDirective implements OnChanges {
  @HostBinding('style.display') display = 'block';
  @HostBinding('style.overflow') overflow = 'hidden';
  @Input() smoothHeight;
  pulse: boolean;
  startHeight: number;

  constructor(private element: ElementRef) {}

  @HostBinding('@grow')
  get grow() {
    return {
      value: this.pulse,
      params: {
        startHeight: this.startHeight,
      },
    };
  }

  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngOnChanges(changes) {
    // console.log('el', this.element.nativeElement.firstChild);

    this.setStartHeight();
    this.pulse = !this.pulse;
  }
}
