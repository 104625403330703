import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscribeService {
  subscriptions: Subscription[] = [];

  constructor() {}

  addSubscribe(subscribe: Subscription) {
    this.subscriptions.push(subscribe);
  }

  unSubscribe() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }
}
