import { GenericModel } from '../../../model/generic-model';
import { EServiceType } from './airline.model';
import { Slugify } from './slugify';
import * as _ from 'lodash';

export enum EInterfaceCode {
  AirlinesLuggagePolicy = 'AIRLINES_LUGGAGE_POLICY',
  Empty = '',
}

export enum EServiceStatus {
  Active = 'ACTIVE',
  NotAvailable = 'NOT_AVAILABLE',
}

export enum EInterfaceType {
  AppSingleEntry = 'APP_SINGLE_ENTRY',
  WebURLRedirect = 'WEB_URL_REDIRECT',
  AppNativeInterface = 'APP_NATIVE_INTERFACE',
}

export interface AirlineServiceDescription {
  id: number;
  idServiceAirport: number;
  language: string;
  order: number;
  body: string;
  title: string;
  sectionTitle: string;
  specificForCountry: number;
  orderBy: number;
}

export class AirlineServiceModel extends GenericModel {
  idServiceAirport: number;
  idService: number;
  airport: string;
  serviceStatus: EServiceStatus;
  serviceUrl?: string;
  interfaceType: EInterfaceType;
  interfaceCode: EInterfaceCode;
  visibile: number;
  servicePrice: string;
  serviceAvailability: string;
  serviceTitle: string;
  serviceDescription: string;
  serviceIcon: string;
  positionInApp: string;
  serviceDescriptions?: AirlineServiceDescription[];
  hasCategories: number;
  serviceSection: string;
  serviceSectionLabel: string;
  serviceImageUrl: string;
  categoriesList: any[];
  serviceColor: string;
  serviceType: EServiceType;

  // uiStoreCellType: EUIStoreCellType;
  // cpuDate: Date;
  // insertUser: number;
  // insertDate: Date;
  // updateUser?: number;
  // updateDate?: Date;
  // sourceUrl?: string;

  private readonly webPath: string;
  // private readonly pathsById = {
  //   52: 'luggage-policy',
  //   53: 'modify-your-booking',
  //   55: 'choose-your-seat',
  //   56: 'add-extra-luggage',
  //   57: 'priority-boarding',
  //   58: 'documents',
  //   59: 'travelling-with-children',
  //   60: 'children-travelling-alone',
  //   61: 'expectant-mothers',
  //   72: 'travelling-with-pets',
  //   62: 'duty-free-shopping',
  //   63: 'book-your-meal',
  //   64: 'duty-free-shopping',
  //   70: 'entertainment',
  //   71: 'wi-fi',
  //   66: 'group-booking',
  //   65: 'routes',
  //   67: 'promos',
  //   68: 'business-miles-points',
  //   69: 'information-on-lounges'
  // };

  constructor(jsonData: object | string) {
    super(jsonData);

    // const path = this.pathsById[this.id]; - vecchio sistema di path
    this.webPath = (!_.isNil(this.webPath) && this.webPath !== '') ? this.webPath : 'service-' + this.id;
  }

  get id(): number {
    return this.idService;
  }

  get name(): string {
    return this.serviceTitle;
  }

  get title(): string {
    return this.serviceTitle;
  }

  get description(): string {
    return this.serviceDescription;
  }

  get image(): string {
    return this.serviceImageUrl;
  }

  get prettyUrl(): string {
    return this.webPath;
  }

  get moreUrl(): string {
    return _.isNil(this.serviceUrl) || this.serviceUrl.toLowerCase() === 'n/a' ? null : this.serviceUrl;
  }

}
