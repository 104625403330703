<div class="container-custom px-0">
  <div class="header__wrapper">
    <div class="header__back">
      <a (click)="back()" *ngIf="needBackButton">
        <i class="etf-arrow-left"></i>
        <span>{{ 'BACK' | translate }}</span>
      </a>
    </div>
    <div class="header__brand">
      <img appUiImageLoader
           [src]="((userTypeService.userSourceSbj | async) === 'FLIO') ? './assets/img/loghi/logo_flio.png' : './assets/img/loghi/logo_sostravel.png'"
           alt="FLIO" *ngIf="showHeaderComponents"/>
      <!--    <img appUiImageLoader src="./assets/img/loghi/logo_sostravel.png" alt="SosTravel" *ngIf="-->
      <!--        (userTypeService.userSourceSbj | async) !== 'FLIO' &&-->
      <!--        showHeaderComponents-->
      <!--      " />-->
    </div>
    <div class="header__language">
      <app-switch-languages [iconLanguage]="true" *ngIf="showLanguage"></app-switch-languages>
      <!-- <div class="header__icon" *ngIf="getIcon() !== null">
          <img src="{{ getIcon() }}" />
        </div> -->
    </div>
  </div>
</div>
