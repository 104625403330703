import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-terminal-gate',
  templateUrl: './terminal-gate.component.html',
  styleUrls: ['./terminal-gate.component.scss']
})
export class TerminalGateComponent implements OnInit {
  @Input() terminal: string;
  @Input() gate: string;

  constructor() {}

  ngOnInit() {}
}
