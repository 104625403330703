import { GenericModel } from '../../../model/generic-model';

export class AirportTerminal extends GenericModel {
  airport: string;
  fsTerminal: string;
  idAirportTerminal: number;
  mapUrl: string;
  noStore: boolean;
  terminal: string;

  constructor(jsonData: object | string) {
    super(jsonData);
  }
}
