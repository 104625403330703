import { Component, OnInit, Input } from '@angular/core';
import { ErrorTypeApp } from '../../enum/enumApp';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  @Input() errorType: string = null;
  public errors: any = ErrorTypeApp;

  constructor(private appService: AppService) {}

  ngOnInit() {
    this.appService.setLoading(false);
  }

  getError(): string {
    return this.errorType ? this.errorType : this.appService.getError();
  }

  getTranslationKey(): string {
    return this.appService.getTranslationMessage();
  }
}
