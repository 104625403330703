import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from './app.component';
import { EtaComponent } from './pages/eta/eta.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { EtaService } from './services/eta.service';
import { DateFormatPipePipe } from './pipes/etaobject.pipe';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { DatePipePipe } from './pipes/date.pipe';
import { CheckFlightPipe } from './pipes/check-flight.pipe';
import { TerminalPipe } from './pipes/terminal.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DurationPipe } from './pipes/duration.pipe';
import { GlobalerrorserviceService } from './services/globalerrorservice.service';
import { GatePipe } from './pipes/gate.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { MatDialogModule } from '@angular/material';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslatePropertiesLoader } from './translatePropertiesLoader';
import { FooterComponent } from './components/footer/footer.component';
import { LanguageService } from './services/language-service.service';
import { HeaderComponent } from './components/header/header.component';
import { UserTypeService } from './services/user-type.service';
import { AppService } from './services/app.service';
import { ErrorComponent } from './components/error/error.component';
import { SmoothHeightDirective } from './smooth-height.directive';
import { FlightCardComponent } from './components/flight-card/flight-card.component';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { AppRoutingService } from './app-routing.service';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { SwitchLanguagesComponent } from './components/switchlanguages/switchlanguages.component';
import { SelectLanguageComponent } from './components/switchlanguages/select-language/select-language.component';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/browser';
import { SharedModule } from './modules/shared/shared.module';
import { TitleService } from './services/title.service';
import { EtaTutorialComponent } from './components/tutorial/eta-tutorial/eta-tutorial.component';
import { TutorialComponent } from './components/tutorial/tutorial/tutorial.component';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { DialogSelectLanguageComponent } from './components/switchlanguages/dialog-select-language/dialog-select-language.component';

if (environment.production) {
  Sentry.init(environment.sentry);
}

registerLocaleData(localeIt, 'it');

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    if (environment.production === true) {
      const eventId = Sentry.captureException(
        error.originalError || error.error || error
      );
      // Sentry.showReportDialog({ eventId });
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    EtaComponent,
    DateFormatPipePipe,
    DatePipePipe,
    CheckFlightPipe,
    TerminalPipe,
    DurationPipe,
    GatePipe,
    SafePipe,
    SwitchLanguagesComponent,
    SelectLanguageComponent,
    DialogSelectLanguageComponent,
    FooterComponent,
    HeaderComponent,
    ErrorComponent,
    FlightCardComponent,
    SmoothHeightDirective,
    ErrorPageComponent,
    TutorialComponent,
    EtaTutorialComponent,
  ],
  imports: [
    SharedModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslatePropertiesLoader
      }
    }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
    AppService,
    UserTypeService,
    EtaService,
    LanguageService,
    AppRoutingService,
    TitleService,
    {provide: ErrorHandler, useClass: GlobalerrorserviceService},
    TranslatePropertiesLoader,
    {provide: TranslateLoader, useClass: TranslatePropertiesLoader},
    {provide: ErrorHandler, useClass: SentryErrorHandler}
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  entryComponents: [SelectLanguageComponent, DialogSelectLanguageComponent, SwitchLanguagesComponent]
})
export class AppModule {
  constructor(private router: Router, private titleService: TitleService, private appRoutingService: AppRoutingService) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        console.log('@TitleService - router events NavigationEnd!');
        this.titleService.autoResolve(this.appRoutingService.getRoutesData());
      });
  }
}
