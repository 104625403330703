import { Category } from './Category';
import { AirportServiceStoreModel } from './airport-service-store-model';
import { EInterfaceCode, EInterfaceType, ServiceCellUI, ServicePositionInApp, ServiceTypeStatus } from '../enum/enumAirport';
import { GenericModel } from '../../../model/generic-model';
import { CardType } from './cardModel';
import * as _ from 'lodash';

export class AirportServiceModel extends GenericModel {
  airport: string;
  hasCategories: number;
  idService: number;
  idServiceAirport: number;
  interfaceCode: EInterfaceCode;
  language: string;
  priceLabel: string;
  serviceAvailability: string;
  serviceColor: string;
  serviceDescription: string;
  serviceIcon: string;
  serviceIconUrl: string;
  serviceInstruction: string;
  servicePrice: string;
  serviceTitle: string;
  serviceUrl: string;
  serviceType: string;
  sourceUr: string;
  visibile: string;
  what: string;
  categoriesList: Category[];
  storeList: AirportServiceStoreModel[];
  interfaceType: EInterfaceType;
  positionInApp: ServicePositionInApp;
  uiStoreCellType: ServiceCellUI;
  serviceStatus: ServiceTypeStatus;
  filterKey: string;

  private readonly webPath: string;
  // private readonly pathsById = {
  //   34: 'timetable',
  //   17: 'avoid-line',
  //   18: 'vip-lounge',
  //   19: 'wi-fi',
  //   20: 'ciaoone',
  //   21: 'to-and-from',
  //   22: 'parking', // non usato
  //   23: 'rent', // non usato
  //   24: 'maps', // non usato
  //   25: 'pharmacy',
  //   27: 'shop',
  //   28: 'atm',
  //   29: 'tax-refund',
  //   30: 'change',
  //   31: 'relax',
  //   32: 'wrap-your-bag',
  //   33: 'toilette',
  //   35: 'aed',
  //   36: 'beauty-fitness',
  //   37: 'business-services',
  //   38: 'charging-station',
  //   39: 'family-entertainment',
  //   40: 'hotel',
  //   41: 'info-point',
  //   42: 'lost-found',
  //   43: 'luggage-storage',
  //   44: 'massage-spa',
  //   45: 'other',
  //   46: 'pet-area',
  //   47: 'prayer-area',
  //   48: 'public-service',
  //   49: 'smoking-area',
  //   50: 'special-assistance'
  // };

  constructor(jsonData: object | string) {
    super(jsonData);
    this.getFilterKey();
    if (this.storeList) {
      this.storeList = this.storeList.map(
        service => new AirportServiceStoreModel(service)
      );
    }

    // const path = this.pathsById[this.id]; - vecchio sistema di path
    this.webPath = (!_.isNil(this.webPath) && this.webPath !== '') ? this.webPath : 'service-' + this.id;
  }

  get id(): number {
    return this.idService;
  }

  get prettyUrl(): string {
    return this.webPath;
  }

  get name(): string {
    return this.serviceTitle;
  }

  get title(): string {
    return this.serviceTitle;
  }

  public getFilterKey() {
    this.filterKey = (
      this.serviceTitle +
      '|' +
      this.serviceDescription
    ).toLowerCase();
  }

  public getImage(): string {
    let response: string = '';
    switch (this.serviceType) {
      case 'FAST_TRACK':
        response = './assets/img/image-single-entry/AVOID_LINE.png';
        break;
      case 'AIRPORT_WI_FI':
        response = './assets/img/image-single-entry/WIFI.png';
        break;
    }
    return response;
  }

  public getCellType() {
    if (this.uiStoreCellType === 'STORE_CELL') {
      return CardType.IMAGE_EXPANSION;
    } else {
      return CardType.DEFAULT;
    }
  }

  public getServiceDetail(id: number | string): AirportServiceStoreModel {
    return this.storeList.find(
      (item: AirportServiceStoreModel) => {
        return item.idServiceAirportStore.toString() === id.toString();
      }
    );
  }
}
