import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { EtaService } from './services/eta.service';
import { Observable } from 'rxjs';
import { GenericResponse } from './model/genericResponse';

@Injectable({
  providedIn: 'root'
})
export class TranslatePropertiesLoader implements TranslateLoader {
  constructor(private apiService: EtaService) {}

  static unicodeToChar(text: string): string {
    return text.replace(/\\u[\dA-Fa-f]{4}/g, match => {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
  }

  transformResponse(data: any): any {
    const locale = 'en';

    // append locales and modules if not exists
    const properties = {};
    properties[locale] = {};

    // set current reading module
    const curModuleMap = [];

    // console.log(data);
    const linesArray = data.split('\n');
    // console.log(linesArray);
    if (linesArray) {
      // split each line to key-value pair and
      // save in properties
      linesArray.forEach(function(line, index, array) {
        let keyValPair,
          value = '';

        line = line.trim();

        // discard empty lines and lines
        // starting with #, which is considered as a comment
        if (line === '' || line.charAt(0) === '#') {
          return;
        }

        keyValPair = line.match(/([^=]*)=(.*)$/);
        if (keyValPair && keyValPair[1]) {
          if (keyValPair[2]) {
            // second value in keyValPair can be undefined
            // when value in the file is empty
            // eg: key =
            value = keyValPair[2].trim();
          }

          curModuleMap[
            keyValPair[1].trim()
          ] = TranslatePropertiesLoader.unicodeToChar(value);
        } else {
          console.error('Invalid line : ' + line);
        }
      });
    }

    return curModuleMap;
  }

  getTranslation(lang: string): Observable<any> {
    return new Observable(observer => {
      this.apiService
        .getTranslations(lang)
        .subscribe((res: GenericResponse) => {
          const parsedData = this.transformResponse(res);
          // console.group("New translation loaded - lang is: " + lang);
          // console.log(parsedData);
          // console.groupEnd();
          observer.next(parsedData);
          observer.complete();
        });
    });
  }
}
