import { Component, Inject } from '@angular/core';
import { LanguageModel } from 'src/app/model/language';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent {
  avaibleLanguages: LanguageModel[] = [];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<SelectLanguageComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private languages: LanguageModel[],
    private translate: TranslateService
  ) {
      this.avaibleLanguages = languages.filter(
        item => item.idioma !== this.translate.currentLang
      );
  }

  openLink(event: MouseEvent): void {
    event.preventDefault();
  }

  switchL(idioma: string) {
    sessionStorage.setItem('idiomaSelected', idioma);
    this.translate.use(idioma);
    const selectedLanguage = this.languages.find(
      language => language.idioma === idioma
    );
    this._bottomSheetRef.dismiss(selectedLanguage);
  }
}
