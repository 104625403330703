import { GenericModel } from '../../../model/generic-model';
import * as _ from 'lodash';
import { AirlineServiceModel, EInterfaceType, EServiceStatus } from './airline-service.model';

export enum EServiceType {
  Safebag24 = 'SAFEBAG24',
}

export enum EUIStoreCellType {
  LuggagePage = 'LUGGAGE_PAGE',
  TextPage = 'TEXT_PAGE',
}

export interface ILuggagePolicy {
  classes: {
    description: string;
    locations: Location[];
    textColor: string;
    backgroundColor: string;
  }[];
}

export interface ILocation {
  description: string;
  bags: IBag[];
}

export interface IBag {
  description: string;
  pieces: string;
  dimensionsCm: string;
  dimensionInches: string;
  weightKg: string;
  weightPound: string;
}

export interface IPhoneNumber {
  id: number;
  fsCodeAirline: string;
  country: string;
  phoneNumber: string;
  tollFree: number;
  orderBy: number;
}

export interface ServiceAirlineByGroup {
  category: {
    name: string;
    label: string;
    image_url: string;
    color: string;
    type: EServiceType;
  };
  child: AirlineServiceModel[];
}

export class AirlineModel extends GenericModel {
  fs: string;
  airlineLogoUrl: string;
  airlineLogoDarkUrl: string;
  airlineName: string;
  airlineWebSite: string;
  airlineMainPhone: string;
  airlineBaggageRulesUrl: string;
  airlineWebCheckInUrl: string;
  airlineTCUrl: string;
  airlineStatus: number;
  url_twitter: string;
  url_facebook: string;
  url_chat: string;
  phoneNumbers: IPhoneNumber[];
  services: AirlineServiceModel[];
  luggagePolicy?: ILuggagePolicy;
  sectionOrder: string[];

  private dataServicesByGroup: ServiceAirlineByGroup[] = [];

  constructor(jsonData: object | string) {
    super(jsonData);
    this.services = this.services.map(service => new AirlineServiceModel(service));
    this.generateServicesByGroup();
  }

  get fsCode(): string {
    return this.fs;
  }

  get name(): string {
    return this.airlineName;
  }

  get hasLuggagePolicy(): boolean {
    return !_.isNil(this.luggagePolicy);
  }

  /**
   * Restituisce i servizi raggruppati per tipologia
   */
  get servicesByGroup(): ServiceAirlineByGroup[] {
    return this.dataServicesByGroup;
  }

  get website(): string | boolean {
    return _.isNil(this.airlineWebSite) ? false : this.airlineWebSite;
  }

  get phone(): string | boolean {
    return _.isNil(this.airlineMainPhone) ? false : this.airlineMainPhone;
  }

  get checkin(): string | boolean {
    return _.isNil(this.airlineWebCheckInUrl) ? false : this.airlineWebCheckInUrl;
  }

  get facebook(): string | boolean {
    return _.isNil(this.url_facebook) ? false : this.url_facebook;
  }

  get twitter(): string | boolean {
    return _.isNil(this.url_twitter) ? false : this.url_twitter;
  }

  get TCUrl(): string | boolean {
    return _.isNil(this.airlineTCUrl) ? false : this.airlineTCUrl;
  }

  /**
   * Cerca e restituisce un servizio dalla path url
   *
   * @param url - La porzione della url
   */
  servicesFinderByUrl(url: string): AirlineServiceModel {
    return this.services.find((service: AirlineServiceModel) => {
      return service.prettyUrl.includes(url);
    });
  }

  servicesFinderById(id: number): AirlineServiceModel {
    return this.services.find((service: AirlineServiceModel) => {
      return service.id === id;
    });
  }

  /**
   * Genera ed organizza array suddividendo i servizi nei macro gruppi presenti nella risposta
   */
  private generateServicesByGroup(): void {
    const serviceGroupOrder: string[] = [];
    const serviceByCat: ServiceAirlineByGroup[] = this.dataServicesByGroup;
    _.each(this.services, (service: AirlineServiceModel) => {
      let index = serviceGroupOrder.indexOf(service.serviceSection);
      if (index < 0) {
        serviceGroupOrder.push(service.serviceSection);
        index = serviceGroupOrder.indexOf(service.serviceSection);

        const category = {
          // cell_type: service.uiStoreCellType,
          name: service.serviceSection,
          label: service.serviceSectionLabel,
          image_url: service.serviceImageUrl,
          color: service.serviceColor,
          type: service.serviceType,
        };

        serviceByCat[index] = {
          category: category,
          child: []
        };
      }
      if (service.serviceStatus === EServiceStatus.Active && service.interfaceType.toString() !== EInterfaceType.AppNativeInterface) {
        serviceByCat[index].child.push(service);
      }
    });

    // clean-up the array - removing the category with empty child
    const cleanCategories = serviceByCat.filter((group: ServiceAirlineByGroup) => {
      return group.child.length > 0;
    });

    // ordinamento delle sezioni
    const order = this.sectionOrder;
    this.dataServicesByGroup = _.sortBy(cleanCategories, function (group: ServiceAirlineByGroup) {
      return order.indexOf(group.category.name) >= 0 ? order.indexOf(group.category.name) : 1000;
    });
  }

}
