/**
 ***********************************************************************
 *
 * SOSTRAVEL.com S.p.A CONFIDENTIAL
 * __________________
 *
 * [2016] - [2018] SOSTRAVEL.com S.p.A
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of SOSTRAVEL.com S.p.A and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to SOSTRAVEL.com S.p.A
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from SOSTRAVEL.com S.p.A.
 *
 * __________________
 *

 * Secured    on (last update) :
 * Documented on (last update) :
 *
 ************************************************************************
 */

import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {UrlParams} from './url-params';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {
  private userSource: string = null;
  public userSourceSbj: BehaviorSubject<string> = new BehaviorSubject<string>('SOSTRAVEL');

  constructor() {
    if (UrlParams.hasParam('us')) {
      const paramUserSource = UrlParams.get('us');
      if (!_.isNil(paramUserSource)) {
        this.setUserSource(paramUserSource);
      }
    }
  }

  setUserSource(userSource: string): void {
    sessionStorage.setItem('userSource', userSource);
    this.userSource = userSource;
    this.userSourceSbj.next(userSource);
  }

  getUserSource(): string | null {
    const userSource = sessionStorage.getItem('userSource');
    return userSource ? userSource : this.userSource;
  }

  isFlio(): boolean {
    const userSource = this.getUserSource();
    return userSource === 'FLIO';
  }

  isSostravel(): boolean {
    return !this.isFlio() && this.getUserSource() !== null;
  }
}
