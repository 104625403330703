import { Component, Input, NgZone, OnInit } from '@angular/core';
import { FlightModel, IFlightCard } from '../../model/flight-model';
import { Router } from '@angular/router';
import { smoothHeight } from '../../animations';
import { NavigateToService } from '../../services/navigate-to.service';
import { UserTypeService } from 'src/app/services/user-type.service';

export enum GROUP_PALETTE_COLOR {
  ICON_DEPARTURE = 'icon departure',
  ICON_ARRIVAL = 'icon arrival',
  TIME_DEPARTURE = 'time departure',
  TIME_ARRIVAL = 'time arrival',
  FORECAST_DEPARTURE = 'forecast departure',
  FORECAST_ARRIVAL = 'forecast arrival',
  FLIGHT_STATUS = 'flight status'
}

@Component({
  selector: 'app-flight-card',
  templateUrl: './flight-card.component.html',
  styleUrls: ['./flight-card.component.scss'],
  animations: [smoothHeight]
})
export class FlightCardComponent implements OnInit {
  @Input() flight: FlightModel;
  @Input() canCollapse: any;
  data: IFlightCard;
  isCollapsed: Boolean = true;
  dateTimer: any = {
    departure: 'date',
    arrival: 'date'
  };
  descriptionPrediction: string = 'SOS_PREDICTION';
  groupPaletteColor = GROUP_PALETTE_COLOR;

  constructor(
    private router: Router,
    public navigateTo: NavigateToService,
    private userTypeService: UserTypeService,
    private zone: NgZone
  ) {}

  /**
   * On View Init
   */
  ngOnInit() {
    console.log('@FlightCardComponent - ngOnInit with flight:', this.flight);
    if (this.canCollapse === false) {
      this.isCollapsed = false;
    }
    this.data = this.flight.getFlightCard();
    // document.documentElement.style.setProperty(
    //   '--color',
    //   this.getColor(GROUP_PALETTE_COLOR.FLIGHT_STATUS)
    // );
    if (this.data.departure.dateWas.isToday) {
      this.dateTimer.departure =
        this.dateTimer.departure === 'date' ? 'label' : 'date';
    }
    if (this.data.arrival.dateWas.isToday) {
      this.dateTimer.arrival =
        this.dateTimer.arrival === 'date' ? 'label' : 'date';
    }
    if (this.userTypeService.getUserSource() === 'FLIO') {
      this.descriptionPrediction = 'FLIO_PREDICTION';
    }
  }

  navigateToAirline(airlineCode: string): void {
    if (!this.isCollapsed) {
      this.zone.run(() => {
        this.navigateTo.airline(airlineCode);
      });
    }
  }

  /**
   * Navigazione al dettaglio dell'aereoporto
   */
  navigateToAirport(airportFS: string): void {
    this.router.navigate(['./airport'], {
      queryParams: { airportCode: airportFS }
    });
  }

  collapseMe(): void {
    if (this.canCollapse === true) {
      console.log('clicked:', this.isCollapsed);
      this.isCollapsed = !this.isCollapsed;
    }
  }

  getColor(group: GROUP_PALETTE_COLOR): string {
    switch (group) {
      case GROUP_PALETTE_COLOR.ICON_DEPARTURE:
        return this.data.departure.iconColor;
        break;
      case GROUP_PALETTE_COLOR.ICON_ARRIVAL:
        return this.data.arrival.iconColor;
        break;
      case GROUP_PALETTE_COLOR.TIME_DEPARTURE:
        return this.data.departure.descriptiveColor;
        break;
      case GROUP_PALETTE_COLOR.TIME_ARRIVAL:
        return this.data.arrival.descriptiveColor;
        break;
      case GROUP_PALETTE_COLOR.FORECAST_DEPARTURE:
        return this.data.departure.forecastColor;
        break;
      case GROUP_PALETTE_COLOR.FORECAST_ARRIVAL:
        return this.data.arrival.forecastColor;
        break;
      case GROUP_PALETTE_COLOR.FLIGHT_STATUS:
        return this.data.flightStatusColor;
        break;
    }
  }
}
