<div id="appErrorComponent">
  <div
    class="error__panel error__link-broken"
    *ngIf="getError() === errors.LINK"
  >
    <img src="./assets/img/error/not-found.png" class="image-responsive" />
    <span>{{ getTranslationKey() | translate }}</span>
  </div>
  <div
    class="error__panel error__page-error"
    *ngIf="getError() === errors.PAGE"
  >
    <i class="etf-error icon-responsive"></i>
    <span>{{ getTranslationKey() | translate }}</span>
  </div>
</div>
