import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { IFilterData } from '../filters.component';
// import { gsap, Power1 } from 'gsap';

export interface IChips {
  selectedColor: string;
  data: IFilterData[];
}

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss']
})
export class FilterChipsComponent implements AfterViewChecked {
  @Input() chips: IChips = null;
  @Output() change: EventEmitter<IFilterData> = new EventEmitter();

  @ViewChild('chipsElement', { static: true }) chipsElement;
  private chipElements: any[] = [];
  private initialized: boolean = false;

  constructor(private sanitizer: DomSanitizer) {}

  /**
   * Imposta la transition per l'animazione successiva
   */
  ngAfterViewChecked(): void {
    // if (this.initialized === false && this.chipsElement.nativeElement.children.length === this.chips.data.length) {
    //   this.initialized = true;
    //   for (const node of this.chipsElement.nativeElement.children) {
    //     // Initialize transforms on node
    //     gsap.set(node, { x: 0 });
    //     this.chipElements.push({
    //       x: node.offsetLeft,
    //       node: node
    //     });
    //   }
    // }
  }

  style(chip: IFilterData): SafeStyle {
    let styles = '';
    if (chip.selected) {
      styles += 'background-color: ' + this.chips.selectedColor + ';';
    }
    return this.sanitizer.bypassSecurityTrustStyle(styles);
  }

  /**
   * Al click sulla chip
   * @param chip
   * @param event
   */
  performClick(chip: IFilterData, event: MouseEvent): void {
    chip.selected = !chip.selected;
    this.change.emit(chip);

    // attende che la classe venga aggiunta alla chip
    // setTimeout(() => {
    //   this.chipAnimation();
    // });
  }

  /**
   * Anima le chip spostandole di posto
   */
  // chipAnimation(): void {
  //   for (const box of this.chipElements) {

  //     const lastX = box.x;
  //     box.x = box.node.offsetLeft;

  //     // Continue if box hasn't moved
  //     if (lastX !== box.x) {
  //       // Reversed delta values taking into account current transforms
  //       const x = gsap.getProperty(box.node, 'x') + lastX - box.x;
  //       let time = Math.abs(x / 300);
  //       time = (time > .8) ? .8 : (time < .3) ? .3 : time;
  //       // Tween to 0 to remove the transforms
  //       gsap.fromTo(box.node, time, { x: x }, { x: 0, ease: Power1.easeInOut });
  //     }

  //   }
  // }
}
