<div class="service" (click)="performClick()" appDeviceType>
  <img class="service__image" appUiImageLoader [src]="icon" [alt]="title"/>
  <div class="service__content">
    <span class="content__title" [innerHTML]="title | highlight: highlight"></span>
    <span class="content__description" *ngIf="showDescription" [innerHTML]="description | highlight: highlight"></span>
  </div>
  <div class="service__icon">
    <i class="etf-arrow-right arrow-right"></i>
  </div>
</div>
