export class CardImageModel {
  id: number | string;
  headerLeft: string;
  headerRight: string;
  images: string[];
  footerLeft: string;
  footerRight: string;
  extras: any;

  constructor(
    id: number | string,
    headerLeft: string,
    headerRight: string,
    images: string[],
    footerLeft: string,
    footerRight: string,
    extras?: any
  ) {
    this.id = id;
    this.headerLeft = headerLeft;
    this.headerRight = headerRight;
    this.images = images;
    this.footerLeft = footerLeft;
    this.footerRight = footerRight;
    if (extras) {
      this.extras = extras;
    }
  }

  hasValue(data: string): boolean {
    return typeof data === 'string' && data !== '';
  }

  isImage(data: string): boolean {
    return data.indexOf('.png') >= 0;
  }
}
