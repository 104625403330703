import { ServiceCellUI } from '../enum/enumAirport';

export enum CardType {
  IMAGE = 'card-image',
  IMAGE_EXPANSION = 'card-image-expansion',
  DEFAULT = 'card'
}

export interface ICardModel {
  type: ServiceCellUI;
  title: string;
  image: string;
  locations: string[];
  gate: string;
  serviceColor?: string;
  hours?: string;
  extras?: any;
}

export class CardModel {
  type: ServiceCellUI;
  title: string;
  image: string;
  locations: string[];
  gate: string;
  serviceColor?: string;
  hours?: string;
  extras?: any;

  constructor(data: ICardModel) {
    for (const key of Object.keys(data)) {
      this[key] = data[key];
    }
  }

  printTile(): boolean {
    if (
      this.type === ServiceCellUI.SERVICE_TITLE ||
      this.type === ServiceCellUI.SERVICE_PICTURE
    ) {
      return true;
    }
    return false;
  }

  printImage(): boolean {
    if (
      this.type === ServiceCellUI.SERVICE_ONLY_LOCATION ||
      this.type === ServiceCellUI.SERVICE_LOGO ||
      this.type === ServiceCellUI.SERVICE_PICTURE
    ) {
      return true;
    }
    return false;
  }

  printLocation(): boolean {
    return true;
  }

  printHours(): boolean {
    if (
      this.type === ServiceCellUI.SERVICE_LOGO ||
      this.type === ServiceCellUI.SERVICE_TITLE ||
      this.type === ServiceCellUI.SERVICE_PICTURE
    ) {
      return true;
    }
    return false;
  }

  verticalCard(): boolean {
    if (
      this.type === ServiceCellUI.SERVICE_TITLE ||
      this.type === ServiceCellUI.SERVICE_LOGO ||
      this.type === ServiceCellUI.SERVICE_PICTURE
    ) {
      return true;
    }
    return false;
  }

  canCollapse(): boolean {
    if (
      this.type === ServiceCellUI.SERVICE_LOGO ||
      this.type === ServiceCellUI.SERVICE_TITLE
    ) {
      return true;
    }
    return false;
  }
}
