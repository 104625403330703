<div id="loader" class="loaderSpace row" *ngIf="inLoading">
  <div class="panel panel-default">
    <img src="./assets/gif/loader.gif" width="150" />
  </div>
</div>
<!-- *ngIf="otherPage && hasError() === false" -->
<app-header [isLoading]="inLoading"></app-header>
<div id="otherPage" #otherPage>
  <router-outlet></router-outlet>
  <app-error *ngIf="hasError()"></app-error>
</div>
<app-footer></app-footer>
