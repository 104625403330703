import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AppService } from '../../services/app.service';
import { UserTypeService } from '../../services/user-type.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NavigateToService } from '../../services/navigate-to.service';
import { AppRoutingService } from 'src/app/app-routing.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean;
  public showHeaderComponents: boolean = false;
  public iconPath: string = '';
  public needBackButton: boolean = false;
  public showLanguage: boolean = false;

  constructor(
    private appService: AppService,
    public userTypeService: UserTypeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigateTo: NavigateToService,
    private routingService: AppRoutingService
  ) {
    router.events.subscribe(
      event => event instanceof NavigationEnd && this.handleRouteChange()
    );
  }

  ngOnInit() {
    this.navigateTo.needBackButtonSbj.subscribe((value: boolean) => {
      this.needBackButton = value;
    });
  }

  handleRouteChange() {
    this.showLanguage = this.routingService.getRoutesData().showLanguage;
    if (this.activatedRoute.children.length > 0) {
      this.activatedRoute.firstChild.data.subscribe(data => {
        if (data.hasOwnProperty('iconPath')) {
          this.iconPath = data.iconPath;
        }
      });
    }
  }

  ngOnChanges(data: object) {
    if (data.hasOwnProperty('isLoading')) {
      setTimeout(() => {
        this.showHeaderComponents = true;
      }, 1000);
    }
  }

  back(): void {
    this.navigateTo.goToPreviousUrl();
  }

  getIcon(): string | null {
    if (this.appService.getHeaderIcon() === null) {
      return null;
    }
    return this.iconPath + this.appService.getHeaderIcon();
  }
}
