import { GenericModel } from '../../../model/generic-model';
import { CardImageModel } from './cardImageModel';
import { CardModel } from './cardModel';

export class AirportServiceStoreModel extends GenericModel {
  static whereSelection: any[] = [
    {
      label: 'ANYWHERE',
      value: 5,
    },
    {
      label: 'LANDSIDE',
      value: 0
    },
    {
      label: 'AIRSIDE',
      value: 1
    },
    {
      label: 'GATE',
      value: 2
    },
    {
      label: 'CHECK_IN',
      value: 3
    },
    {
      label: 'ARRIVALS',
      value: 4
    }
  ];
  afterSecurityCheck: number;
  idServiceAirport: number;
  idServiceAirportStore: number;
  imageRating: string;
  language: string;
  nearestTo: string;
  openingHours: string;
  phoneNumber1: string;
  phoneNumber2: string;
  sourceUrl: string;
  storeCategories: string;
  storeDescription: string;
  storeImage: string;
  storeLink: string;
  storeName: string;
  storeReview: string;
  storeTags: string;
  storeTerminal: string;
  visibile: number;
  searchString: string;

  get name(): string {
    return this.storeName;
  }

  public static whereName(data: number): string {
    return this.whereSelection[data + 1].label;
  }

  constructor(jsonData: object | string) {
    super(jsonData);
    this.getSearchString();
  }

  public getSearchString() {
    this.searchString = (
      this.storeName +
      '#' +
      this.storeTags +
      '#' +
      this.storeCategories +
      '#' +
      this.storeDescription +
      '#' +
      this.storeTerminal +
      '#' +
      this.nearestTo
    ).toLowerCase();
  }

  transformToCardModel(type: any): CardModel {
    const locationName = [
      this.storeTerminal + ' | ',
      AirportServiceStoreModel.whereName(this.afterSecurityCheck)
    ];
    return new CardModel({
      type: type,
      title: this.storeName,
      image: this.storeImage,
      locations: locationName,
      gate: this.nearestTo,
      hours: this.openingHours,
      extras: {
        storeDescription: this.storeDescription
      }
    });
  }

  transformToCardImageModel(): CardImageModel {
    const gateName =
      this.nearestTo.toUpperCase() !== 'N/A'
        ? '<span class="label-gate">Gate </span>' + this.nearestTo
        : '';
    return new CardImageModel(
      this.idServiceAirportStore,
      this.storeName,
      gateName,
      [this.storeImage],
      '',
      this.imageRating,
      {
        storeDescription: this.storeDescription,
        storeTerminal: this.storeTerminal,
        whereName: AirportServiceStoreModel.whereName(this.afterSecurityCheck),
        openingHours: this.openingHours
      }
    );
  }
}
