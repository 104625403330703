import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ErrorTypeApp } from '../enum/enumApp';
import { Subject } from 'rxjs';
import { AppRoutingService } from '../app-routing.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public inLoadingChange: Subject<boolean> = new Subject<boolean>();
  private typeError: string = null;
  private inLoading: boolean = false;
  private routeData: any = {};
  private header: any = {
    icon: null,
    iconPath: ''
  };
  private translationMessages: any = {
    [ErrorTypeApp.LINK]: 'BROKEN_LINK',
    // s: 'SERVICE_NOT_AVAILABLE',
    [ErrorTypeApp.PAGE]: 'PAGE_ERROR'
  };

  constructor(
    private router: Router,
    protected routingService: AppRoutingService,
    private deviceService: DeviceDetectorService
  ) {
    router.events.subscribe(
      event => event instanceof NavigationEnd && this.handleRouteChange()
    );

    const deviceType: string = this.deviceIsMobile() ? 'mobile' : 'desktop';
    document
      .getElementsByTagName('body')[0]
      .classList.add('device--' + deviceType);
  }

  getError(): string {
    return this.typeError;
  }

  setError(error: string): void {
    this.typeError = error;
  }

  setTranslationMessage(trabnslation: string): void {
    this.translationMessages[this.typeError] = trabnslation;
  }

  getTranslationMessage(): string {
    return this.translationMessages[this.typeError];
  }

  setLoading(action: boolean): void {
    this.inLoading = action;
    this.inLoadingChange.next(action);
  }

  isLoading(): boolean {
    console.log('@AppService - inLoading:', this.inLoading);

    return this.inLoading;
  }

  handleRouteChange(): void {
    this.header.icon = null;
    this.routeData = this.routingService.getRoutesData();
  }

  needBackButton(): boolean {
    // console.log(
    //   '@needBackButton - route data:',
    //   this.routingService.getRoutesData()
    // );
    if (this.routeData && typeof this.routeData.addBack !== undefined) {
      return this.routeData.addBack;
    }
    return false;
  }

  // setHeaderIcon(icon: string): void {
  //   this.header.icon = icon;
  // }

  getHeaderIcon(): string {
    return this.header.icon;
  }

  // getHeaderIconPath(): string {
  //   return this.header.iconPath;
  // }

  deviceIsMobile(): boolean {
    return !this.deviceIsDesktop();
  }

  deviceIsDesktop(): boolean {
    return window.innerWidth >= 768 && this.deviceService.isDesktop();
  }
}
