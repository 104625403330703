/**
 ***********************************************************************
 *
 * SOSTRAVEL.com S.p.A CONFIDENTIAL
 * __________________
 *
 * [2016] - [2018] SOSTRAVEL.com S.p.A
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of SOSTRAVEL.com S.p.A and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to SOSTRAVEL.com S.p.A
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from SOSTRAVEL.com S.p.A.
 *
 * __________________
 *
 * Secured    on (last update) :
 * Documented on (last update) :
 *
 ************************************************************************
 */

import { AirportWeatherModel } from './AirportWeatherModel';
import { AirportServiceModel } from './airport-service-model';
import { AirportTerminal } from './AirportTerminal';
import { ExchangeRateModel } from './exchangeRateModel';
import { GenericModel } from '../../../model/generic-model';
import { AirlineServiceModel } from '../../airline/models/airline-service.model';

export class AirportModel extends GenericModel {
  id: number;
  fs: string;
  iata: string;
  icao: string;
  faa: string;
  name: string;
  street1: string;
  street2: string;
  city: string;
  cityCode: string;
  district: string;
  stateCode: string;
  postalCode: string;
  countryCode: string;
  countryName: string;
  regionName: string;
  timeZoneRegionName: string;
  weatherZone: string;
  localTime: Date;
  utcOffsetHours: number;
  latitude: number;
  longitude: number;
  classification: number;
  active: boolean;
  delayIndexUrl: string;
  weatherUrl: string;
  displayValue: string;
  searchValue: string;
  passengers: number;
  safebagshop: number;
  safebag24Price: number;
  safebag24WithWrapPrice: number;
  shopmanager: string;
  urlWebsiteShop: string;
  flightInfoPrice: number;
  countryCurrency: string;
  waitTimeChecked: number;
  waitTimeCheckIn: number;
  waitTimePassport: number;
  waitTimeSecurityControl: number;
  currency: string;
  refundDailyDelay: number;
  refundMaxDelay: number;
  refundMaxDamage: number;
  refundMaxLoss: number;
  refundMaxPilfering: number;
  email: string;
  departureDate: Date;
  arrivalDate: Date;
  number: number;
  exchangeRates: JSON;
  exchangeRateModel: ExchangeRateModel;
  weatherNow: AirportWeatherModel;
  weatherForecast: AirportWeatherModel[];
  services: AirportServiceModel[];
  terminals: AirportTerminal[];

  constructor(jsonData: object | string) {
    super(jsonData);

    this.services = this.services.map(service => new AirportServiceModel(service));
    this.terminals = this.terminals.map(
      terminal => new AirportTerminal(terminal)
    );
  }

  getFsCode(): string {
    return this.fs;
  }

  getName(): string {
    return this.name;
  }

  /**
   * Cerca e restituisce un servizio dalla path url
   *
   * @param url - La porzione della url
   */
  servicesFinderByUrl(url: string): AirportServiceModel {
    console.log('@AirportModel: this.services', this.services);
    return this.services.find((service: AirportServiceModel) => {
      console.log('service.prettyUrl', service.prettyUrl);
      return service.prettyUrl.includes(url);
    });
  }

  servicesFinderById(id: number): AirportServiceModel {
    return this.services.find((service: AirportServiceModel) => {
      return service.id === id;
    });
  }
}
