import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-eta-tutorial',
  templateUrl: './eta-tutorial.component.html',
  styleUrls: ['./eta-tutorial.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EtaTutorialComponent implements OnInit {
  public tutorialSteps = [];

  constructor(private translate: TranslateService) {
    this.tutorialSteps = [
      {
        name: 'apt',
        title: this.translate.instant('TUTORIAL_ETA_STEP_APT_TITLE'),
        description: this.translate.instant(
          'TUTORIAL_ETA_STEP_APT_DESCRIPTION'
        ),
        arrow: 'eta/apt_arrow.png',
        elevateSelector: '.travel_airports__label',
        scroll: true,
        spot: true
      },
      {
        name: 'airline',
        title: this.translate.instant('TUTORIAL_ETA_STEP_AIRLINE_TITLE'),
        description: this.translate.instant(
          'TUTORIAL_ETA_STEP_AIRLINE_DESCRIPTION'
        ),
        arrow: 'eta/apt_arrow.png',
        elevateSelector:
          '.flight_card[data-collapsed="false"] .flight__airline img',
        scroll: true,
        spot: true
      }
    ];
  }

  ngOnInit() {}
}
