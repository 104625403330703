<div class="flight_card flight_card--status-{{ data.status }}" [attr.data-collapsed]="isCollapsed"
     (click)="collapseMe()" [class.flight_card--delayed]="data.departure.inDelay || data.arrival.inDelay">
  <div class="flight_section">
    <div class="flight__airline">
      <img (click)="navigateToAirline(data.airline.fs)" src="{{ data.airline.logo }}" alt="{{ data.airline.name }}"/>
    </div>
    <div [style.background-color]="getColor(groupPaletteColor['FLIGHT_STATUS'])" class="flight__info">
      <div class="flight__number">
        <span>{{ data.carrierFlightNumber }}</span>
      </div>
      <div class="flight__status">
        <span [style.background-color]="
            getColor(groupPaletteColor['FLIGHT_STATUS'])
          ">{{ data.statusLabel }}</span>
      </div>
    </div>
  </div>
  <div class="itinerary_section" [smoothHeight]="isCollapsed">
    <div class="itinerary_section__wrapper">
      <div class="itinerary_section__content">
        <div *ngFor="let type of ['departure', 'arrival']" class="itinerary__row itinerary__row--{{ type }}"
             [class.delayed]="data[type].inDelay">
          <span class="itinerary__timing" *ngIf="data[type].inDelay === true">
            <span class="time__delay">{{
              data[type].descriptiveStrikeOutHour | dateTime: 'time'
              }}</span>
            <span class="time__descriptive" [style.color]="
                getColor(groupPaletteColor['TIME_' + type | uppercase])
              ">{{ data[type].descriptiveTiming }}</span>
          </span>
          <div class="itinerary__detail">
            <div class="itinerary__col_flight">
              <div class="itinerary__detail_time">
                <div class="itinerary__time">
                  <div class="time__was">
                    <div class="itinerary__icon">
                      <span>
                        <i class="etf-{{ type }}-airplane" alt="Icon arrival"></i>
                      </span>
                    </div>
                    <span class="time__hours">
                      <ng-template [ngIf]="data[type].inDelay">
                        <span [style.color]="
                            getColor(
                              groupPaletteColor['TIME_' + type | uppercase]
                            )
                          ">{{
                          data[type].dateNow.moment
                            | dateTime: 'timeWithoutPm'
                          }}</span>
                      </ng-template>
                      <ng-template [ngIf]="data[type].inDelay === false">
                        <span [style.color]="
                            getColor(
                              groupPaletteColor['TIME_' + type | uppercase]
                            )
                          ">{{
                          data[type].dateWas.moment
                            | dateTime: 'timeWithoutPm'
                          }}</span>
                      </ng-template>
                    </span>
                    <span class="time__detail" [style.color]="
                        getColor(groupPaletteColor['TIME_' + type | uppercase])
                      ">
                      <span class="time__airport-name">{{
                        data[type].airport_fs
                        }}</span>
                      <span class="time__meridiem">
                        <ng-template [ngIf]="data[type].inDelay">
                          {{ data[type].dateNow.moment | dateTime: 'pm' }}
                        </ng-template>
                        <ng-template [ngIf]="data[type].inDelay === false">
                          {{ data[type].dateWas.moment | dateTime: 'pm' }}
                        </ng-template>
                      </span>
                    </span>
                  </div>
                </div>
                <span class="itinerary__city-date">
                  <span class="itinerary__city">{{ data[type].city }}</span>
                  <span class="itinerary__date">
                    <span *ngIf="dateTimer[type] === 'date'">{{
                      data[type].dateWas.moment | dateTime: 'date'
                      }}</span>
                    <span class="itinerary_date__today"
                          *ngIf="dateTimer[type] !== 'date'">{{ 'TODAY' | translate }}</span>
                  </span>
                </span>
              </div>
              <span class="time__prediction" *ngIf="data[type].forecastDate !== null">
                {{ descriptionPrediction | translate }}:
                <span class="time__forecast" [style.color]="
                    getColor(groupPaletteColor['FORECAST_' + type | uppercase])
                  ">{{ data[type].forecastDate | dateTime: 'time'
                  }}{{ data[type].forecastGateDelay }}</span>
              </span>
            </div>
            <app-terminal-gate [terminal]="data[type].terminal" [gate]="data[type].gate"></app-terminal-gate>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
