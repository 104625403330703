import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { noop } from 'rxjs';

@Injectable()
export class ScrollToService {

  private targetElement: HTMLElement = document.documentElement || (<HTMLElement>document.body.parentNode) || document.body;
  private scrollDirection: string = 'vertical';

  constructor() {
  }

  get target(): HTMLElement {
    return this.targetElement;
  }

  set target(element: HTMLElement) {
    this.targetElement = element;
  }

  get direction() {
    return this.scrollDirection;
  }

  /**
   * Imposta la direzione di scroll
   * @param direction - 'vertical' o 'horizontal'
   */
  set direction(direction: string) {
    this.scrollDirection = direction;
  }

  public scrollTo(to: number, options?: { relative?: boolean; duration?: number; speed?: number, callback?: any }) {
    options = Object.assign({speed: 300, callback: noop()}, options);

    let deltaScroll: number = to;
    if (options.relative) {
      deltaScroll = this.position();
      if (to < 0) {
        deltaScroll -= Math.abs(to);
      } else {
        deltaScroll += Math.abs(to);
      }
    }


    const callback = options.callback;
    let duration: number = 500;
    if (!_.isNil(options)) {
      if (!_.isNil(options.speed) && typeof options.speed === 'number') {
        duration = Math.abs(this.position() - deltaScroll) / options.speed * 1000;
      }
      if (!_.isNil(options.duration) && typeof options.speed === 'number') {
        duration = options.duration;
      }
    }

    const animationFrameReqFunc = this.reqAnimationFrame();
    const start = this.position(),
      change = deltaScroll - start,
      incrementTime = 20;

    if (change !== 0) {
      let currentTime = 0;
      const animateScroll = () => {
        // increment the time
        currentTime += incrementTime;
        // find the value with the quadratic in-out easing function
        const val = this.easeInOutCubic(currentTime, start, change, duration);
        // move the document.body
        this.move(val);
        // do the animation unless its over
        if (currentTime < duration) {
          animationFrameReqFunc(animateScroll);
        } else {
          if (callback && typeof (callback) === 'function') {
            // the animation is done so lets callback
            callback();
          }
        }
      };
      animateScroll();
    }
  }

  // easing functions https://github.com/nibo-ai/easing-ts/blob/master/src/easing.ts
  private easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
      return c / 2 * t * t + b;
    }
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  private easeInCubic = function (t, b, c, d) {
    const tc = (t /= d) * t * t;
    return b + c * (tc);
  };

  private easeInOutCubic = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
      return c / 2 * t * t * t + b;
    }
    t -= 2;
    return c / 2 * (t * t * t + 2) + b;
  };

  private inOutQuintic = function (t, b, c, d) {
    const ts = (t /= d) * t,
      tc = ts * t;
    return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
  };

  private reqAnimationFrame() {
    const callbackFunction = (cb: any) => {
      window.setTimeout(cb, 1000 / 60);
    };
    // tslint:disable-next-line:max-line-length
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || (<any>window).mozRequestAnimationFrame || callbackFunction;
  }

  private position(): number {
    if (this.direction === 'vertical') {
      return this.target.scrollTop;
    } else {
      return this.target.scrollLeft;
    }
  }

  private move(amount) {
    // document.documentElement.scrollTop = amount;
    // (<Element>document.body.parentNode).scrollTop = amount;
    // document.body.scrollTop = amount;
    if (this.direction === 'vertical') {
      this.target.scrollTop = amount;
    } else {
      this.target.scrollLeft = amount;
    }
  }
}
