import * as _ from 'lodash';

export class GenericModel {
  public searchString: string;

  constructor(jsonData: object | string) {
    if (!_.isNil(jsonData)) {
      let jsonObject: any = jsonData;
      if (typeof jsonData === 'string') {
        jsonObject = JSON.parse(jsonData);
      }
      for (const prop of Object.keys(jsonObject)) {
        this[prop] = jsonObject[prop];
      }
    }
  }
}
