import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../model/genericResponse';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { AirportService } from '../modules/airport/services/airport.service';

@Injectable({
  providedIn: 'root'
})
export class EtaService {
  private urlTranslations = environment.apiUrl + 'sostraveller/etatranslation';
  private urlTrack = environment.apiUrl + 'sostraveller/eta';
  private urlTimetableDeparture =
    environment.apiUrl + 'sostraveller/timetable/departure';
  private urlTimetableArrival =
    environment.apiUrl + 'sostraveller/timetable/arrival';
  private urlAirport = environment.apiUrl + 'airports/eta/';
  private urlVipLounge = environment.apiUrl + 'viplounge';
  private urlCovid = environment.apiUrl + 'bridger/covid19/main/';
  private today: Date;
  private formatToday: string;
  public fs: any;
  public difference: any;

  constructor(
    protected service: HttpClient,
    private airportService: AirportService
  ) {}

  getLanguage(): string {
    let language = sessionStorage.getItem('idiomaSelected');
    language = language ? language : 'en';
    return language;
  }

  getRequestOptions(addHeader?: string[]): object {
    const headerDict = {
      'Content-Type': 'text/plain',
      // "X-WATCHWORD-ETA": "{#!%$MrkSmnAS0StrL18$%!#}",
      'X-WATCHWORD': '{#!%$MrkSmnAeA24$%!#}',
      'X-PREFERRED-LANGUAGE': this.getLanguage()
      // 'cache-control': 'no-cache, must-revalidate, post-check=0, pre-check=0',
    };
    // if (addHeader && addHeader.indexOf("x-eta") >= 0) {
    //   headerDict["X-WATCHWORD-ETA"] = "{#!%$MrkSmnAS0StrL18$%!#}";
    // }
    if (addHeader) {
      if (addHeader.indexOf('jwtfake') >= 0) {
        headerDict['JWT'] = 'FAKE';
      }
      if (addHeader.indexOf('no-cache') >= 0) {
        headerDict['Cache-Control'] = 'no-cache';
      }
    }
    return {
      headers: new HttpHeaders(headerDict)
    };
  }

  getTranslations(lang: string): Observable<GenericResponse> {
    const url = `${this.urlTranslations}/${lang}`;
    return this.service.get<GenericResponse>(
      url,
      Object.assign(this.getRequestOptions(), {
        responseType: 'text'
      })
    );
  }

  getTrackingFinal(
    idTravel: string,
    getSingleFlight: boolean
  ): Observable<GenericResponse> {
    let url = `${this.urlTrack}/TV${idTravel}?lang=` + this.getLanguage();
    if (getSingleFlight === true) {
      url = `${this.urlTrack}/SB${idTravel}?lang=` + this.getLanguage();
    }
    return this.service.get<GenericResponse>(url, this.getRequestOptions());
  }

  getTimetable(
    fsCode: string,
    type: string = 'departure'
  ): Observable<GenericResponse> {
    let url =
      type === 'departure'
        ? this.urlTimetableDeparture
        : this.urlTimetableArrival;
    url += `/${fsCode}`;
    return this.service.get<GenericResponse>(
      url,
      this.getRequestOptions(['x-eta'])
    );
  }

  getAirport(airportCode: string): Observable<GenericResponse> {
    this.today = new Date();
    this.formatToday = moment(this.today).format('YYYY/MM/DD');
    const airportUrl =
      `${this.urlAirport}${airportCode}/${this.formatToday}?lang=` +
      this.getLanguage();
    return this.service
      .get<GenericResponse>(airportUrl, this.getRequestOptions(['x-eta']))
      .pipe(
        tap(data => {
          this.airportService.setAirport(data);
        })
      );
  }

  getVipLounge(airportFsCode: string): Observable<GenericResponse> {
    const urlArr =
      `${this.urlVipLounge}/${airportFsCode}?lang=` + this.getLanguage();
    return this.service.get<GenericResponse>(
      urlArr,
      this.getRequestOptions(['jwtfake'])
    );
  }

  getCovid(): Observable<GenericResponse> {
    const urlArr = this.urlCovid + this.getLanguage();
    return this.service.get<GenericResponse>(
      urlArr,
      this.getRequestOptions(['x-eta'])
    );
  }
}
