import { Routes, RouterModule } from '@angular/router';
import { EtaComponent } from './pages/eta/eta.component';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ErrorPageComponent } from './pages/error-page/error-page.component';

const routes: Routes = [
  {
    path: 'airport',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./modules/airport/airport.module').then(m => m.AirportModule)
  },
  {
    path: 'airline',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./modules/airline/airline.module').then(m => m.AirlineModule)
  },
  {
    path: 'flight/:id',
    component: EtaComponent,
    data: {
      addBack: false,
      singleFlight: true,
      showLanguage: true
    }
  },
  {
    path: ':id',
    component: EtaComponent,
    data: {
      addBack: false,
      singleFlight: false,
      showLanguage: true
    }
  },
  {
    path: '**',
    component: ErrorPageComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false
    }),
    HttpClientModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
