<div class="header">
  <span class="close-toggle" [matDialogClose]></span>
  <h2>Select language</h2>
</div>
<div class="language-list">
  <div *ngFor="let language of avaibleLanguages" class="language" (click)="switchL(language.idioma)">
    <a mat-list-item class="text">
      <span mat-line><img src="./assets/img/country-flag/{{ language.idioma }}.png"
          class="img-language" />{{ language.idiomaDescription }}</span>
    </a>
  </div>
</div>
