import { Injectable } from '@angular/core';
import { AirportServiceModel } from '../models/airport-service-model';
import { GenericResponse } from '../../../model/genericResponse';
import { AirportModel } from '../models/airport-model';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import {
  ActivatedRoute,
  Router,
  ActivatedRouteSnapshot
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AirportService {
  public airportSource: AirportModel;
  public airportSourceChange: Subject<string> = new Subject<string>();

  constructor(private route: ActivatedRoute, private router: Router) {}

  setAirport(data: GenericResponse) {
    this.airportSource = new AirportModel(data.response);
    this.airportSourceChange.next(moment().toString());
  }

  getAirportSingleService(
    id: number | string,
    primaryKey: string = 'idService'
  ): AirportServiceModel {
    return this.airportSource.services.find(
      (service: AirportServiceModel) => service[primaryKey] === id
    );
  }

  getAirportServiceByType(type: string): AirportServiceModel {
    return this.getAirportSingleService(type, 'serviceType');
  }

  getAirportServiceByRoute(url: string = ''): AirportServiceModel {
    const path =
      url !== '' ? url : this.route.snapshot.firstChild.firstChild.url[0].path;
    return this.getAirportSingleService(path, 'prettyUrl');
  }

  getAirportServiceById(id: number): AirportServiceModel {
    return this.getAirportSingleService(id);
  }

  getAirportServiceDetail(
    serviceFounded: AirportServiceModel,
    detailId: number | string
  ) {
    return serviceFounded.storeList.find(
      item => item.idServiceAirportStore.toString() === detailId
    );
  }

  getAirportSource(): AirportModel {
    return new AirportModel(this.airportSource);
  }
}
