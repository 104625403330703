import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTime',
  pure: false
})
export class DateTimePipe implements PipeTransform {
  private formats = {
    Q12H: {
      date: 'MM/DD/YYYY',
      shortDate: 'MM/DD/YY',
      time: 'hh:mm A',
      timeWithoutPm: 'hh:mm',
      pm: 'A',
      day: 'ddd'
    },
    Q24H: {
      date: 'DD/MM/YYYY',
      shortDate: 'DD/MM/YY',
      time: 'HH:mm',
      timeWithoutPm: 'HH:mm',
      pm: '',
      day: 'ddd'
    }
  };

  constructor(protected translate: TranslateService) {}

  getFormat(formatType: string): string {
    switch (this.translate.currentLang) {
      case 'en':
      case 'ar':
        return this.formats.Q12H[formatType];
        break;
      default:
        if (formatType === 'pm') {
          return null;
        }
        return this.formats.Q24H[formatType];
        break;
    }
  }

  transform(value: any, formatType: string = 'date') {
    if (value === null) {
      return '';
    }
    if (this.getFormat(formatType) !== null) {
      return moment(value).format(this.getFormat(formatType));
    } else {
      return '';
    }
  }
}
