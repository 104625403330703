import * as _ from 'lodash';

export class UrlParams {

  private static search(): string {
    const search = window.location.search;
    if (!_.isNil(search) && !_.isEmpty(search) && search.length > 1) {
      return search.substring(1);
    }
    return null;
  }

  static hasParams(): boolean {
    return !_.isNil(UrlParams.search());
  }

  static hasParam(paramName: string): boolean {
    return UrlParams.hasParams() && UrlParams.search().indexOf(paramName) >= 0;
  }

  static getObject(): { [key: string]: string } {
    const search = UrlParams.search();
    if (UrlParams.hasParams()) {
      const objectParams = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
        return key === '' ? value : decodeURIComponent(value);
      });
      return objectParams;
    }
  }

  static get(paramName: string): string {
    const params = UrlParams.getObject();
    if (params.hasOwnProperty(paramName)) {
      return params[paramName];
    }
    return null;
  }

}
