/**
 ***********************************************************************
 *
 * SOSTRAVEL.com S.p.A CONFIDENTIAL
 * __________________
 *
 * [2016] - [2018] SOSTRAVEL.com S.p.A
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of SOSTRAVEL.com S.p.A and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to SOSTRAVEL.com S.p.A
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from SOSTRAVEL.com S.p.A.
 *
 * __________________
 *
 * Secured    on (last update) :
 * Documented on (last update) :
 *
 ************************************************************************
 */

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { AppService } from '../../services/app.service';
import { LanguageService } from '../../services/language-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EtaService } from '../../services/eta.service';
import { ErrorTypeApp } from '../../enum/enumApp';
import { TrackingServiceModel } from '../../model/trackingServiceModel';
import { UserTypeService } from '../../services/user-type.service';
import { TranslateService } from '@ngx-translate/core';
import { FlightModel, FlightProgressInfo, IFlightCardDate } from '../../model/flight-model';
import * as moment from 'moment';
import { SubscribeService } from '../../services/subscribe.service';
import { NavigateToService } from '../../services/navigate-to.service';
import { TitleService } from 'src/app/services/title.service';
import Padding = google.maps.Padding;

@Component({
  selector: 'app-eta',
  templateUrl: './eta.component.html',
  styleUrls: ['./eta.component.scss']
})
export class EtaComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;
  @ViewChild('google_map__container', {static: true}) gmapElement: ElementRef;
  @ViewChild('travel_detail', {static: false}) travelDetailRef: ElementRef;
  public map: google.maps.Map;
  public mapType: google.maps.MapTypeId;
  public google;
  public travelID: string;
  public flights: FlightModel[] = [];
  public _flightProgressValue = 0;
  public poly: google.maps.Polyline;
  public googleMapsLoaded;
  public flightProgressInfo: FlightProgressInfo = null;
  public flightActiveIndex: number;
  public flightActiveStatus: string;
  public tickIntervalViewObj: any = {
    flightInfo: 'duration'
  };
  public tickers: any = {
    forMapProgress: null,
    mapFlightProgress: null
  };
  public date: number = Date.now();
  private openedInfoWindow: google.maps.InfoWindow = null;
  private trackingService: TrackingServiceModel;
  private planeImage: any;
  private planeMarker: google.maps.Marker;

  constructor(
    public appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private etaService: EtaService,
    private userTypeService: UserTypeService,
    private translateService: TranslateService,
    private zone: NgZone,
    private cdRef: ChangeDetectorRef,
    private subscribeService: SubscribeService,
    public navigateTo: NavigateToService,
    private titleService: TitleService
  ) {
    console.log('@EtaComponent: constructor');
    this.travelID = this.route.snapshot.paramMap.get('id');
    this.subscribeService.addSubscribe(
      languageService.idiomaChange.subscribe(() => {
        this.appService.setLoading(true);
        this.flights = [];
        this.getTravel().then(
          () => {
            this.addTickerForMapProgress();
          },
          () => {
          }
        );
      })
    );

  }

  ngAfterViewInit() {
    window['initMap'] = () => {
      console.log('Google map is Loaded');

      this.googleMapsIsLoaded();
      this.cdRef.detectChanges();
    };

    if (!window.document.getElementById('google-map-script')) {
      console.log('add google maps script');

      const s = window.document.createElement('script');
      s.id = 'google-map-script';
      s.type = 'text/javascript';
      s.defer = true;
      s.async = true;
      s.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAwBqJdt-bXlloba6-rDyXgquDqOGFyorU&libraries=geometry&callback=initMap';

      window.document.body.appendChild(s);
    } else {
      this.googleMapsIsLoaded();
      this.cdRef.detectChanges();
      console.log('google maps is loaded');
    }
  }

  navigateToAirport(fsCode: string): void {
    this.zone.run(() => {
      this.navigateTo.airport(fsCode);
    });
  }

  googleMapsIsLoaded() {
    this.googleMapsLoaded = true;
    // this.cdRef.detectChanges();
    this.google = google;
    this.mapType = google.maps.MapTypeId.ROADMAP;
    // carico il viaggio
    this.getTravel().then(
      () => {
        this.addTickerForMapProgress();
      },
      () => {
      }
    );
  }

  ngOnDestroy() {
    for (const tickerName of Object.keys(this.tickers)) {
      clearInterval(this.tickers[tickerName]);
    }
    this.subscribeService.unSubscribe();
  }

  /**
   * Crea un ticker per effettuare lo switch/swap degli elementi html
   */
  addTickerMapFlightProgress() {
    this.zone.runOutsideAngular(() => {
      this.tickers.mapFlightProgress = setInterval(() => {
        this.zone.run(() => {
          this.swapFlightProgressInfo();
        });
      }, 1500);
    });
  }

  addTickerForMapProgress() {
    this.zone.runOutsideAngular(() => {
      this.tickers.forMapProgress = setInterval(() => {
        this.zone.run(() => {
          this._flightProgressValue = this.getFlightProgress();
          this.moveAirplaneMarker();
        });
      }, 500);
    });
  }

  swapFlightProgressInfo(): void {
    let newFlightInfo = this.tickIntervalViewObj.flightInfo;
    switch (newFlightInfo) {
      case 'duration':
        newFlightInfo = 'in_flight';
        break;
      case 'in_flight':
        newFlightInfo = 'landing_in';
        break;
      case 'landing_in':
        newFlightInfo = 'duration';
        break;
    }
    this.tickIntervalViewObj.flightInfo = newFlightInfo;
    clearInterval(this.tickers.mapFlightProgress);
    this.addTickerMapFlightProgress();
  }

  public trackByCard(index: number, flight: FlightModel): number {
    if (!flight) {
      return null;
    }
    return flight.flightId;
  }

  /**
   * Google Maps Inizialization
   */
  initMap() {
    const mapProperties = {
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      scroolwheel: false,
      disableDefaultUI: true,
      scaleControl: false,
      rotateControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      minZoom: 1.5,
      maxZoom: 8.6
    };

    this.map = new google.maps.Map(
      document.getElementById('google_map__container'),
      mapProperties
    );
    this.map.setOptions({gestureHandling: 'greedy'});

    this.map.addListener('drag', () => {
      this.closeInfoWindow();
    });
    this.map.addListener('click', () => {
      this.closeInfoWindow();
    });

    this.setMap();
  }

  closeInfoWindow(): void {
    if (this.openedInfoWindow !== null) {
      this.openedInfoWindow.close();
      this.openedInfoWindow = null;
    }
  }

  openInfoWindow(title: string, marker: google.maps.Marker, content: string = ''): void {
    this.closeInfoWindow();
    this.openedInfoWindow = new google.maps.InfoWindow({
      content: '<span class="iw-t">' + title + '</span><span class="iw-c">' + content + '</span>'
    });
    this.openedInfoWindow.open(this.map, marker);
  }

  setMap() {
    this.poly = new google.maps.Polyline({
      strokeColor: '#f9720a',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      geodesic: true
    });
    this.poly.setMap(this.map);

    const airportsPoint = this.getAllPointsForTravel();
    const airportsPointArray = Object.keys(airportsPoint).map(
      i => airportsPoint[i]
    );
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

    // genera la linea e i markers
    for (const airportFs of Object.keys(airportsPoint)) {
      const data = airportsPoint[airportFs];
      const point = data['position'] as google.maps.LatLng;
      this.addMarkerToMap(point, data['icon'], data['content']);

      this.poly.getPath().push(point);
      // estende i bound integrando il nuovo punto sulla mappa
      bounds.extend(point);
    }

    this.zone.runOutsideAngular(() => {
      window.onresize = () => {
        this.zone.run(() => {
          this.fitMapOnAvailableSpace(bounds);
        });
      };
    });
    this.fitMapOnAvailableSpace(bounds);

    this.addPlaneOnMap();
  }

  /**
   * Fitta la mappa nello spazio disponibile
   * se desktop: verifica la dimensione e posizione del travel-detail
   * se mobile: si estende per tutta la larghezza tenendo condo di un GAP di default
   */
  fitMapOnAvailableSpace(bounds: google.maps.LatLngBounds): void {
    const mapGap: number = 15;
    let leftOffset: number = mapGap;
    if (this.appService.deviceIsDesktop()) {
      // recupera la dimensione del pannello travel (desktop)
      const travelDetailBounds = (this.travelDetailRef.nativeElement as HTMLElement).getBoundingClientRect();
      leftOffset = travelDetailBounds.width + mapGap;
    }
    // Fitta la mappa per far visualizzare tutte i marker
    const pad: Padding = {
      bottom: mapGap,
      left: leftOffset,
      right: mapGap,
      top: mapGap,
    };
    this.map.fitBounds(bounds, pad);
  }

  /**
   * Recupera tutti i punti del viaggio impostando per ognuno icona (partenza, arrivo o connessione) e oggetto LatLngdi Google
   */
  getAllPointsForTravel() {
    const responseObject = {};

    let count = 0;
    // genera tutti i punti degli aereoporti
    this.flights.forEach((currentFlight, index) => {
      responseObject[currentFlight.departureModel.fs] = {
        order: count,
        isLanded: currentFlight.isLanded(),
        position: new google.maps.LatLng(
          currentFlight.departureModel.latitude,
          currentFlight.departureModel.longitude
        ),
        icon: index === 0 ? 'iconDeparting' : 'iconConnection',
        content: currentFlight.departureModel.fs
      };
      count++;
      // genera i marker per l'atterraggio
      if (index + 1 === this.flights.length) {
        responseObject[currentFlight.arrivalModel.fs] = {
          order: count,
          isLanded: currentFlight.isLanded(),
          position: new google.maps.LatLng(
            currentFlight.arrivalModel.latitude,
            currentFlight.arrivalModel.longitude
          ),
          icon: 'iconArriving',
          content: currentFlight.departureModel.fs
        };
      }
    });

    return responseObject;
  }

  addMarkerToMap(
    position: google.maps.LatLng,
    iconName: string,
    title: string,
  ): google.maps.Marker {
    const mapIcons: { [key: string]: google.maps.Icon } = {
      iconDeparting: {
        url: 'assets/img/map-eta/departure.png',
        scaledSize: new google.maps.Size(24, 24),
        anchor: new google.maps.Point(12, 12)
      },
      iconArriving: {
        url: 'assets/img/map-eta/arrive.png',
        scaledSize: new google.maps.Size(24, 24),
        anchor: new google.maps.Point(12, 12)
      },
      iconConnection: {
        url: 'assets/img/map-eta/linked_airport.png',
        scaledSize: new google.maps.Size(24, 24),
        anchor: new google.maps.Point(12, 12)
      }
    };

    const marker = new google.maps.Marker({
      position: position,
      map: this.map,
      icon: mapIcons[iconName],
      zIndex: -3000
    });
    marker.addListener('click', () => {
      const iwContent: string = (iconName === 'iconArriving') ? 'ARRIVAL' : 'DEPARTURE';
      this.openInfoWindow(title, marker, this.translateService.instant(iwContent));
    });

    return marker;
  }

  /**
   * Effettua lo switch tra le tipologie di mappe
   */
  changeMapType() {
    if (this.map) {
      if (this.map.getMapTypeId() === google.maps.MapTypeId.ROADMAP) {
        this.mapType = google.maps.MapTypeId.SATELLITE;
      } else {
        this.mapType = google.maps.MapTypeId.ROADMAP;
      }
      this.map.setMapTypeId(this.mapType);
    }
  }

  /**
   * Verifica la tipologia attiva della GoogleMap
   *
   * @param mapTypeName
   */
  isMapType(mapTypeName: google.maps.MapTypeId): boolean {
    return this.mapType === mapTypeName;
  }

  /**
   * Recupera le informazioni del volo attive,
   * solo se non è atterrato
   */
  getFlightProgressInfo() {
    const flight: FlightModel = this.getActiveFlight().flight;
    if (flight.isLanded() === false) {
      this.flightProgressInfo = flight.getFlightProgressInfo();
    }
  }

  /**
   * Recupera il primo volo attivo
   */
  getActiveFlight() {
    const notLandedFlight: FlightModel[] = this.flights.filter(
      (flight: any) => flight.isLanded() === false
    );
    let activeFlight: FlightModel = notLandedFlight[0];
    if (notLandedFlight.length === 0) {
      activeFlight = this.flights[this.flights.length - 1];
    }

    const positionDeparture = new google.maps.LatLng(
      activeFlight.departureModel.latitude,
      activeFlight.departureModel.longitude
    );
    const positionArrival = new google.maps.LatLng(
      activeFlight.arrivalModel.latitude,
      activeFlight.arrivalModel.longitude
    );

    return {
      flight: activeFlight,
      positionDeparture: positionDeparture,
      positionArrival: positionArrival
    };
  }

  /**
   * Aggiunge il marker dell'aereo sulla mappa di Google
   */
  addPlaneOnMap(): void {
    const activeFlight = this.getActiveFlight();

    const positionAndRotation = this.calcolatePositionAndRotation(
      activeFlight.flight.getFlightProgress(),
      activeFlight.positionDeparture,
      activeFlight.positionArrival
    );

    // tslint:disable: max-line-length
    this.planeImage = {
      path:
        'M104.12,54.93H84.42L73.28,41.23a38.72,38.72,0,0,0,5.14-.86c1.82-.52,1-2.52.37-4.3A19.91,19.91,0,0,0,78,34.16a1.52,1.52,0,0,0-1.06-.8c-3-.59-9.68.16-9.68.16l-6.85-9.43h6c1.34,0,1.71-.85,1.3-2.35-.49-1.78-1-3.93-2.16-4.5-1.71-.86-10.28,0-10.28,0L41.58,1.82c-6-4.28-8.56,0-8.56,2.57,0,1.92,8.9,17.81,13.7,27.41,6,12,6.86,13.71,8.57,18s-2.57,5.14-6,5.14-30,.86-30,.86S10.74,44.65,9,42.94s-6-2.57-7.71-.86C0,43.44-.39,44.65.46,46.37,1.08,47.61,4.62,56.92,6,60.44a3.78,3.78,0,0,1,0,2.69C4.62,66.65,1.08,76,.46,77.21c-.85,1.71-.49,2.93.86,4.28,1.71,1.71,6,.86,7.71-.86S19.31,67.78,19.31,67.78s26.56.86,30,.86,7.71.86,6,5.14-2.57,6-8.57,18c-4.8,9.6-13.7,25.5-13.7,27.41,0,2.57,2.57,6.86,8.56,2.57l13.71-15.42s8.57.86,10.28,0c1.14-.57,1.67-2.72,2.16-4.49.41-1.5,0-2.36-1.3-2.36h-6l6.85-9.42s6.68.74,9.68.15a1.5,1.5,0,0,0,1.06-.8,18,18,0,0,0,.77-1.91c.63-1.78,1.45-3.77-.37-4.3a39.86,39.86,0,0,0-5.14-.85L84.42,68.64h19.7s15.42,0,15.42-6.85S104.12,54.93,104.12,54.93Z',
      fillOpacity: 0.9,
      anchor: new google.maps.Point(52, 52),
      strokeWeight: 0,
      fillColor: '#90061A',
      scale: 0.24,
      rotation: positionAndRotation.rotation,
      zIndex: 3000
    };
    // tslint:enable: max-line-length

    const planeMarker = this.planeMarker = new google.maps.Marker({
      position: positionAndRotation.position,
      map: this.map,
      icon: this.planeImage
    });

    this.planeMarker.addListener('click', () => {
      this.openInfoWindow(activeFlight.flight.getFlightCard().carrierFlightNumber, this.planeMarker, this.translateService.instant('FLIGHT'));
    });
  }

  moveAirplaneMarker(): void {
    if (google && this.planeImage && this.planeMarker) {
      const activeFlight = this.getActiveFlight();

      const positionAndRotation = this.calcolatePositionAndRotation(
        activeFlight.flight.getFlightProgress(),
        activeFlight.positionDeparture,
        activeFlight.positionArrival
      );

      this.planeImage.rotation = positionAndRotation.rotation;
      this.planeMarker.setIcon(this.planeImage);
      this.planeMarker.setPosition(positionAndRotation.position);
    }
  }

  calcolatePositionAndRotation(
    progress: number,
    departureCoords: google.maps.LatLng,
    arrivalCoords: google.maps.LatLng
  ): any {
    const SphericalUtil = google.maps.geometry.spherical;

    const distanceFromAirport = SphericalUtil.computeDistanceBetween(
      departureCoords,
      arrivalCoords
    );

    const stepPct = 6000 / distanceFromAirport; // estimate parameter change for 6km of flight ~ 30s @ 800km/h
    const loc = SphericalUtil.interpolate(
      departureCoords,
      arrivalCoords,
      progress / 100.0
    );
    const nextLoc = SphericalUtil.interpolate(
      departureCoords,
      arrivalCoords,
      progress / 100.0 + stepPct
    );
    const heading = SphericalUtil.computeHeading(loc, nextLoc);

    return {
      rotation: heading - 90, // rimuovo 90 gradi perchè l'aereo è ruotato
      position: loc
    };
  }

  /**
   * Recupera i dettagli sull'itinerario
   */
  getTravel() {
    console.log('API viaggio');
    clearInterval(this.tickers.forMapProgress);
    return new Promise((resolve, reject) => {
      let getSingleFlight = false;
      if (this.route.snapshot.data.singleFlight !== undefined) {
        getSingleFlight = this.route.snapshot.data.singleFlight;
      }
      this.subscribeService.addSubscribe(
        this.etaService
          .getTrackingFinal(this.travelID, getSingleFlight)
          .subscribe(
            data => {
              console.log('API viaggio - completata');
              setTimeout(() => {
                console.log('API viaggio - rimuovo loading');
                this.appService.setLoading(false);
              }, 500);
              // Se viene restituito errore nella risposta
              if (!data.success) {
                this.appService.setError(ErrorTypeApp.LINK);
                return;
              }
              this.appService.setError(null);
              this.trackingService = data.response as TrackingServiceModel;
              this.userTypeService.setUserSource(
                this.trackingService.userSource
              );
              const flightsId = [];
              this.trackingService.flights.forEach(flight => {
                const fm: FlightModel = new FlightModel(this.translateService);
                console.log('fm', fm);
                fm.modelFromRawJson(flight);
                this.flights.push(fm);
                flightsId.push(fm.carrier + fm.numberFlight);
              });
              this.titleService.setTitle('ETA ' + flightsId.join(' - '));

              // inizializza la google maps
              this.initMap();
              this.getFlightProgressInfo();
              this.addTickerMapFlightProgress();
              resolve();
            },
            error => {
              setTimeout(() => {
                this.appService.setLoading(false);
              }, 500);
              console.log('error.status', error.status);
              // if (error.status === 404) {
              this.appService.setError(ErrorTypeApp.LINK);
              // } else {
              // this.appService.setError(ErrorTypeApp.PAGE);
              // }
              reject();
            }
          )
      );
    });
  }

  /**
   * Recupera tutti gli fsCode degli Aereoporti
   */
  getAllAirportInItinerary(): string[] {
    const airportsFs = [];
    this.flights.forEach((flight, index) => {
      if (index === 0) {
        airportsFs.push(flight.departureModel.fs, flight.arrivalModel.fs);
      } else {
        airportsFs.push(flight.arrivalModel.fs);
      }
    });
    return airportsFs;
  }

  /**
   * Calcola il tempo di connessione tra 2 voli
   *
   * @param index - l'indice del volo da prendere in esame
   */
  getConnectionTime(index: number): string {
    let connectionFormat = '';
    const arrivalTime: IFlightCardDate = this.flights[
      index
      ].getArrivingDateTime();
    if (typeof this.flights[index + 1] === 'object') {
      const departureTime: IFlightCardDate = this.flights[
      index + 1
        ].getDepartingDateTime();
      const dateMoment: moment.Moment = moment(departureTime.moment);
      const differenceTime = dateMoment.diff(arrivalTime.moment);
      const diffDuration = moment.duration(differenceTime);
      const weeks = diffDuration.weeks();
      const days = diffDuration.days();
      const hours = diffDuration.hours();
      const minutes = diffDuration.minutes();

      connectionFormat =
        (weeks > 0 ? weeks + 'w ' : '') +
        (days > 0 ? days - 7 * weeks + 'd ' : '') +
        (hours > 0 ? hours + 'h ' : '') +
        (minutes > 0 ? minutes + 'm' : '');
    }

    return connectionFormat;
  }

  getFlightProgress(): number {
    let flightProgressValue: number = 0;
    // if (this.flights === []) {
    const flightActive: FlightModel = this.getActiveFlight().flight;
    this.flights.forEach((flight, index) => {
      if (flight === flightActive) {
        flightProgressValue = flight.getFlightProgress();
        this.flightActiveIndex = index;
        this.flightActiveStatus = flight.getStatusLabel();
      }
    });
    // }
    return flightProgressValue;
  }

  getFlightProgressLabel(): string {
    const progress = this.getFlightProgress();
    if (progress > 0) {
      return this.flights[0].getStatusLabel();
    }
    return '';
  }
}
