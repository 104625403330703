import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss']
})
export class ServiceItemComponent {

  @Input() highlight: string = null;
  @Input() title: string = null;
  @Input() icon: string = null;
  @Input() description: string = null;
  @Input() showDescription: boolean = true;
  @Output() clicked = new EventEmitter();

  constructor() { }

  performClick(): void {
    this.clicked.emit();
  }

}
