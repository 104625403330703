import { ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language-service.service';
import { AppService } from './services/app.service';
import { ViewportScroller } from '@angular/common';
import { ErrorTypeApp } from './enum/enumApp';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public otherPage: boolean = false;
  public inLoading: boolean;
  @ViewChild('otherPage', { static: false }) otherPageRef: ElementRef;
  @HostBinding('class.isIE') isIE: boolean = false;

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private translate: TranslateService,
    private appService: AppService,
    private cdRef: ChangeDetectorRef,
    private viewportScroller: ViewportScroller,
  ) {
    // Set the default language for translation strings, and the current language.
    translate.setDefaultLang('en');
    languageService.setIdioma(languageService.getLanguageIdioma());
    translate.use(languageService.getLanguageIdioma());
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    // router.events
    //   .pipe(
    //     filter(event => event instanceof Scroll),
    //     map(event => event as Scroll),
    //   )
    //   .subscribe(event => {
    //     console.log('EEEEEE', event);

    //     if (event.position) {
    //       // backward navigation
    //       viewportScroller.scrollToPosition(event.position);
    //     } else if (event.anchor) {
    //       // anchor navigation
    //       viewportScroller.scrollToAnchor(event.anchor);
    //     } else {
    //       // forward navigation
    //       viewportScroller.scrollToPosition([0, 0]);
    //     }
    //   });
    console.log('@AppComponent: constructor');
    appService.inLoadingChange.subscribe(value => {
      this.inLoading = value;
      // run change detection explicitly after the change
      cdRef.detectChanges();
    });
    const browser = this.getBrowser();
    if (browser.name === 'IE' && browser.version === '11') {
      this.isIE = true;
    }
  }

  ngOnInit() {
    console.log('@AppComponent: ngInit');
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
  }

  getBrowser() {
    const ua = navigator.userAgent;
    let tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  }

  hasError(): boolean {
    return this.appService.getError() !== null;
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.appService.setLoading(true);
      this.otherPage = false;
      console.log('@AppComponent: navigator interceptor > NavigationStart');
    }
    if (event instanceof NavigationEnd) {
      this.otherPage = true;
      console.log(
        '@AppComponent: navigator interceptor > NavigationEnd',
        event
      );
      this.viewportScroller.scrollToPosition([0, 0]);
      // if (this.activatedRoute.children.length <= 0) {
      //   this.appService.setLoading(false);
      //   this.appService.setError(ErrorTypeApp.PAGE);
      // }
    }
    if (event instanceof NavigationCancel || event instanceof NavigationError) {
      this.appService.setLoading(false);
      this.appService.setError(ErrorTypeApp.PAGE);
      console.error(
        '@AppComponent: navigator interceptor > NavigationCancel OR NavigationError',
        event
      );
    }
  }

  public showLoader(): boolean {
    console.log('@AppService - inLoading:', this.appService.isLoading());
    return this.appService.isLoading();
  }
}
