import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { LanguageService } from 'src/app/services/language-service.service';
import { LanguageModel } from 'src/app/model/language';
import { DialogPosition, MatDialog } from '@angular/material';
import { DialogSelectLanguageComponent } from './dialog-select-language/dialog-select-language.component';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-switch-languages',
  templateUrl: 'switchLanguages.component.html',
  styleUrls: ['switchLanguages.component.scss']
})
export class SwitchLanguagesComponent {
  @Input('iconLanguage') iconLanguage: boolean;
  @ViewChild('headerSwitch', {static: false}) headerRef: ElementRef;
  @ViewChild('footerSwitch', {static: false}) footerRef: ElementRef;
  private selectedLanguageDescription: string = '';
  private selectedLanguageIdioma: string = '';
  private bottomSheetOpened = false;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _dialog: MatDialog,
    public languageService: LanguageService,
    private appService: AppService
  ) {
    this.selectedLanguageDescription = languageService.getLanguageDescription();
    this.selectedLanguageIdioma = languageService.getLanguageIdioma();
  }

  /**
   * Apre in base al device il selettore della lingua
   *  desktop > dialog positionata accando al toggle cliccato
   *  mobile > bottom sheet
   *
   * @param type - string - definisce il bottone cliccato (header o footer)
   */
  openSelectLanguage(type: string): void {

    if (this.appService.deviceIsDesktop()) {
      const width: number = 530;
      const position: DialogPosition = {};
      if (type === 'header') {
        const _h: HTMLElement = this.headerRef.nativeElement;
        const _b: ClientRect | DOMRect = _h.getBoundingClientRect();
        position.left = (_b.left + _b.width - width) + 'px';
        position.top = (_b.top + _b.height + 20) + 'px';
      } else if (type === 'footer') {
        const _f: HTMLElement = this.footerRef.nativeElement;
        const _b: ClientRect | DOMRect = _f.getBoundingClientRect();
        position.left = _b.left + 'px';
        position.bottom = (window.innerHeight - _b.top + 20) + 'px';
      }

      const dialogRef = this._dialog.open(DialogSelectLanguageComponent, {
        data: this.languageService.getLanguages(),
        width: width + 'px',
        position: position
      })
        .afterClosed()
        .subscribe((data: LanguageModel) => {
          if (data !== undefined) {
            this.languageService.setIdioma(data.idioma);
            this.selectedLanguageDescription = data.idiomaDescription;
            this.selectedLanguageIdioma = data.idioma;
          }
        });
    } else {
      if (this.bottomSheetOpened === false) {
        this._bottomSheet
          .open(SelectLanguageComponent, {
            data: this.languageService.getLanguages()
          })
          .afterDismissed()
          .subscribe((data: LanguageModel) => {
            if (data !== undefined) {
              this.languageService.setIdioma(data.idioma);
              this.selectedLanguageDescription = data.idiomaDescription;
              this.selectedLanguageIdioma = data.idioma;
            }
            this.bottomSheetOpened = false;
          });
        this.bottomSheetOpened = true;
      } else {
        this._bottomSheet.dismiss();
        this.bottomSheetOpened = false;
      }
    }
  }

  getLanguageIdioma(): string {
    return this.languageService.getLanguageIdioma();
  }

  getLanguageName(): string {
    return this.languageService.getLanguageDescription();
  }
}
