import { AirportModel } from '../modules/airport/models/airport-model';
import { AirportAirlineModel } from './airport-airline-model';
import { DateFormat } from './dateFormat';
import { FlightProgressModel } from './flightProgressModel';
import { TranslateService } from '@ngx-translate/core';
import { FSFlightTrack } from './FSFlightTrack';
import { FlightTrackModel } from './FlightTrackModel';
import { DateTimezones } from './DateTimezones';
import * as moment from 'moment';
import * as _ from 'lodash';

export interface FlightProgressInfo {
  flightDuration: string;
  inFlightTime: string;
  landingInTime: string;
}

export interface IIataEquipmentModel {
  iata: string;
  name: string;
  turboProp: number;
  jet: number;
  widebody: number;
  regional: number;
}

export interface IFlightCardDate {
  moment: Date;
  delayInMinutes: string;
  isToday: boolean;
}

export interface IFlightCardAirport {
  airport_fs: string;
  city: string;
  dateWas: IFlightCardDate;
  dateNow: IFlightCardDate;
  inDelay: boolean;
  delay: number;
  terminal: string;
  gate: string;
  descriptiveStrikeOutHour: Date;
  descriptiveTiming: string;
  descriptiveColor: string;
  iconColor: string;
  forecastDate: Date;
  forecastGateDelay: string;
  forecastColor: string;
}

export interface IFlightCardAirline {
  name: string;
  logo: string;
  fs: string;
}

export interface IFlightCard {
  status: string;
  carrierFlightNumber: string;
  statusLabel: string;
  airline: IFlightCardAirline;
  departure: IFlightCardAirport;
  arrival: IFlightCardAirport;
  connectionFlight: number;
  flightStatusColor: string;
}

export interface IDelayPrediction {
  forecastDepartureDate: DateFormat;
  forecastArrivalDate: DateFormat;
  forecastGateDepartureDelay: number;
  forecastGateArrivalDelay: number;
  forecastDepartureColor: string;
  forecastArrivalColor: string;
}

export class FlightModel {
  idFlight: number;
  departure: string;
  arrival: string;
  departureDate: Date;
  arrivalDate: Date;
  carrier: string;
  numberFlight: string;
  status: string;
  flightId: number;
  departureModel: AirportModel;
  arrivalModel: AirportModel;
  carrierModel: AirportAirlineModel;
  flightStatus: FSFlightTrack;
  flightTrack: FlightTrackModel;
  flightOrder: number;
  connectionFlight: number;
  active: number;
  statusDescription: string;
  fsLastUpdate: Date;
  boardingPassBase64: string;
  airplaneModel: string;
  sendSMS: number;
  departingGate: string;
  departingTerminal: string;
  departingDelay: number;
  arrivingGate: string;
  arrivingTerminal: string;
  arrivingDelay: number;
  calculatedDepartingGate: number;
  webCheckinOpenOn: DateTimezones;
  webCheckinCloseOn: DateTimezones;
  checkinOpenOn: DateTimezones;
  checkinCloseOn: DateTimezones;
  boardingOpenOn: DateTimezones;
  boardingClosedOn: DateTimezones;
  gateOpenOn: DateTimezones;
  gateClosedOn: DateTimezones;
  departingDate: DateTimezones;
  DateTimezones: DateTimezones;
  dateForTakeOff: DateTimezones;
  dateForLanding: DateTimezones;
  iataEquipmentModel: IIataEquipmentModel;
  flightEquipmentIataCode: string;
  flightProgressModel: FlightProgressModel;
  fsIdleThresh: number;
  fsDepArrThresh: number;
  mile: number;
  isCodeshare: boolean;
  delay: boolean;
  onTime: boolean;
  connectionTime: any;
  day: number;
  arrivingBaggageCarousel: string;
  hasCodeshare: boolean;
  lastFlightInfoOn: Date;
  welcomeMessageOn: Date;
  imageString: string;
  departing: Date;
  searchString: string;
  difference: any;
  arrivingDate: DateFormat;
  dateNow: Date;
  timeOrLate: string;
  am_pm: string;
  am_pmArr: string;
  was: string;
  depdateDefined: Date;
  arrdateDefined: Date;
  visibleExpandedBox: boolean;
  visibleShort: boolean;
  oneFlight: boolean;
  moreFlight: boolean;
  visibleLanded: boolean;
  listFlightModel: FlightModel[];
  tailNumber: string;
  delayPrediction: IDelayPrediction;
  descriptiveDepartureTiming: string;
  descriptiveArrivalTiming: string;
  descriptiveStrikeOutDepartureHour: Date;
  descriptiveStrikeOutArrivalHour: Date;
  descriptiveDepartureColor: string;
  descriptiveArrivalColor: string;
  flightStatusColor: string;
  departureIconColor: string;
  arrivalIconColor: string;
  pastFlight: number;
  backgroundCell: string;

  constructor(private translate: TranslateService) {}

  modelFromRawJson(json: any) {
    this.idFlight = json['idFlight'];
    this.departure = json['departure'];
    this.arrival = json['arrival'];
    this.carrier = json['carrier'];
    this.status = json['status'];
    this.numberFlight = json['numberFlight'];
    this.mile = json['mile'];
    this.flightId = json['flightId'];
    this.isCodeshare = json['isCodeshare'];
    this.departureModel = json['departureModel'];
    this.arrivalModel = json['arrivalModel'];
    const airline: AirportAirlineModel = new AirportAirlineModel(
      json['carrierModel']
    );
    this.carrierModel = airline;
    this.departingGate = json['departingGate'];
    this.departingTerminal = json['departingTerminal'];
    this.departingDelay = json['departingDelay'];
    this.departureDate = json['departureDate'];
    this.arrivingGate = json['arrivingGate'];
    this.arrivingTerminal = json['arrivingTerminal'];
    this.arrivalDate = json['arrivalDate'];
    this.arrivingDelay = json['arrivingDelay'];
    this.connectionFlight = json['connectionFlight'];
    this.day = json['day'];
    this.arrivingBaggageCarousel = json['arrivingBaggageCarousel'];
    this.flightOrder = json['flightOrder'];
    this.active = json['active'];
    this.statusDescription = json['statusDescription'];
    this.hasCodeshare = json['hasCodeshare'];
    this.calculatedDepartingGate = json['calculatedDepartingGate'];
    this.fsLastUpdate = json['fsLastUpdate'];
    this.lastFlightInfoOn = json['lastFlightInfoOn'];
    this.welcomeMessageOn = json['welcomeMessageOn'];
    this.imageString = json['imageString'];
    this.departing = json['departing'];
    this.searchString = json['searchString'];
    this.flightTrack = json['flightTrack'];
    this.departingDate = json['departingDate'];
    this.arrivingDate = json['arrivingDate'];
    this.depdateDefined = json['depdateDefined'];
    this.arrdateDefined = json['arrdateDefined'];
    this.dateForLanding = json['dateForLanding'];
    this.flightProgressModel = json['flightProgressModel'];
    this.tailNumber = json['tailNumber'];
    this.delayPrediction = json['delayPrediction'];
    this.descriptiveDepartureTiming = json['descriptiveDepartureTiming'];
    this.descriptiveArrivalTiming = json['descriptiveArrivalTiming'];
    this.descriptiveStrikeOutDepartureHour =
      json['descriptiveStrikeOutDepartureHour'];
    this.descriptiveStrikeOutArrivalHour =
      json['descriptiveStrikeOutArrivalHour'];
    this.descriptiveDepartureColor = json['descriptiveDepartureColor'];
    this.descriptiveArrivalColor = json['descriptiveArrivalColor'];
    this.flightStatusColor = json['flightStatusColor'];
    this.pastFlight = json['pastFlight'];
    this.backgroundCell = json['backgroundCell'];
  }

  /**
   * Restituisce la data di effettiva partenza o di arrivo
   *
   * @param type string - 'departing' o 'arriving'
   *
   * @returns type IFlightCardDate - oggetto con moment, formato data e formato ora
   */
  public getFinalDate(type: string = 'departing'): IFlightCardDate {
    let dateMoment: moment.Moment;
    let delayInMinutes: number;

    switch (type) {
      case 'departing':
        dateMoment = moment(this.departingDate.dateLocal).seconds(0);
        delayInMinutes = this.departingDelay;
        break;
      case 'departure':
        dateMoment = moment(this.departureDate).seconds(0);
        delayInMinutes = this.departingDelay;
        break;
      case 'arriving':
        dateMoment = moment(this.arrivingDate.dateLocal).seconds(0);
        delayInMinutes = this.arrivingDelay;
        break;
      case 'arrival':
        dateMoment = moment(this.arrivalDate).seconds(0);
        delayInMinutes = this.arrivingDelay;
        break;
    }

    return {
      moment: dateMoment.toDate(),
      delayInMinutes: this.minutesConverter(delayInMinutes),
      isToday: dateMoment.isSame(moment(), 'day')
    };
  }

  /**
   * Restituisce l'oggetto per la Flight Card
   */
  public getFlightCard(): IFlightCard {
    const departure = this.departureModel;
    const arrival = this.arrivalModel;

    return {
      status: this.status.toLowerCase(),
      carrierFlightNumber: this.carrier + this.numberFlight,
      statusLabel: this.getStatusLabel(),
      flightStatusColor: this.flightStatusColor,
      airline: {
        name: this.carrierModel.getName(),
        logo: this.carrierModel.getLogo(),
        fs: this.carrierModel.fs
      },
      departure: {
        airport_fs: departure.fs,
        city: departure.city,
        dateWas: this.getDepartureDateTime(),
        dateNow: this.getDepartingDateTime(),
        inDelay: this.getDelay('departing') > 0,
        delay: this.getDelay('departing'),
        terminal: this.getDepartingTerminal(),
        gate: this.getDepartingGate(),
        descriptiveTiming: this.descriptiveDepartureTiming,
        descriptiveStrikeOutHour: this.descriptiveStrikeOutDepartureHour,
        descriptiveColor: this.descriptiveDepartureColor,
        iconColor: this.departureIconColor,
        forecastDate: this.getForecastDepartureDate(),
        forecastGateDelay: this.getForecastGateDepartureDelay(),
        forecastColor: this.getForecastDepartureColor()
      },
      arrival: {
        airport_fs: arrival.fs,
        city: arrival.city,
        dateWas: this.getArrivalDateTime(),
        dateNow: this.getArrivingDateTime(),
        inDelay: this.getDelay('arriving') > 0,
        delay: this.getDelay('arriving'),
        terminal: this.getArrivingTerminal(),
        gate: this.getArrivingGate(),
        descriptiveTiming: this.descriptiveArrivalTiming,
        descriptiveStrikeOutHour: this.descriptiveStrikeOutArrivalHour,
        descriptiveColor: this.descriptiveArrivalColor,
        iconColor: this.arrivalIconColor,
        forecastDate: this.getForecastArrivalDate(),
        forecastGateDelay: this.getForecastGateArrivalDelay(),
        forecastColor: this.getForecastArrivalColor()
      },
      connectionFlight: this.connectionFlight
    };
  }

  /**
   * Restutisce la stringa della predizione, costituita dall'orario e dai minuti di ritardo/anticipo con cui partirà/atterrerà il volo
   */
  // public getDelayPrediction(type: string = 'departing') {
  //   const dateTimePipe = new DateTimePipe();
  //   if (this.delayPrediction !== undefined) {
  //     if (type === 'departing') {
  //       return (
  //         dateTimePipe.transform(
  //           this.delayPrediction.forecastDepartureDate.dateLocal,
  //           'time'
  //         ) +
  //         ' +' +
  //         this.minutesConverter(this.delayPrediction.forecastGateDepartureDelay)
  //       );
  //     } else {
  //       return (
  //         dateTimePipe.transform(
  //           this.delayPrediction.forecastDepartureDate.dateLocal,
  //           'time'
  //         ) +
  //         ' +' +
  //         this.minutesConverter(this.delayPrediction.forecastGateArrivalDelay)
  //       );
  //     }
  //   }
  // }

  /**
   * Restutisce i minuti di ritardo con cui partirà/atterrerà il volo
   */
  public getDelay(type: string = 'departing') {
    if (type === 'departing') {
      return this.departingDelay;
    } else {
      return this.arrivingDelay;
    }
  }

  /**
   * Restutisce l'oggetto data di arrivo finale
   */
  getDepartureDateTime(): IFlightCardDate {
    return this.getFinalDate('departure');
  }

  /**
   * Restutisce l'oggetto data di arrivo finale
   */
  getDepartingDateTime(): IFlightCardDate {
    return this.getFinalDate('departing');
  }

  /**
   * Restutisce l'oggetto data di arrivo finale
   */
  getArrivalDateTime(): IFlightCardDate {
    return this.getFinalDate('arrival');
  }

  /**
   * Restutisce l'oggetto data di arrivo finale
   */
  getArrivingDateTime(): IFlightCardDate {
    return this.getFinalDate('arriving');
  }

  /**
   * Restutisce il Terminal di partenza
   */
  getDepartingTerminal() {
    if (this.departingTerminal) {
      return this.departingTerminal;
    }
    return '';
  }

  /**
   * Restutisce il Terminal di arrivo
   */
  getArrivingTerminal() {
    if (this.arrivingTerminal) {
      return this.arrivingTerminal;
    }
    return '';
  }

  /**
   * Restutisce il gate di partenza
   */
  getDepartingGate() {
    if (this.departingGate) {
      return this.departingGate;
    }
    return '--';
  }

  /**
   * Restutisce il gate di arrivo
   */
  getArrivingGate() {
    if (this.arrivingGate) {
      return this.arrivingGate;
    }
    return '--';
  }

  /**
   * Return if flight has connection to another flight
   */
  hasConnection(): number {
    return this.connectionFlight;
  }

  /**
   * Return the translation label of status
   */
  getStatusLabel(): string {
    return this.translate.instant('FLIGHT_STATUS_' + this.status);
  }

  /**
   * Return the flight duration
   */
  getFlightDuration(): string {
    const data = this.flightProgressModel.flightDuration;
    return data === '' ? '--' : data;
  }

  /**
   * Return the flight duration
   */
  getInFlightTime(): string {
    const data = this.flightProgressModel.inFlight;
    return data === '' ? '--' : data;
  }

  /**
   * Return the flight duration
   */
  getLandingInTime(): string {
    const data = this.flightProgressModel.landing;
    return data === '' ? '--' : data;
  }

  getForecastDepartureDate(): Date {
    const prediction = this.delayPrediction;
    if (
      !_.isNil(prediction) &&
      !_.isNil(prediction.forecastDepartureDate) &&
      !_.isNil(prediction.forecastDepartureDate.dateLocal)
    ) {
      return this.delayPrediction.forecastDepartureDate.dateLocal;
    }
    return null;
  }

  getForecastArrivalDate(): Date {
    const prediction = this.delayPrediction;
    if (
      !_.isNil(prediction) &&
      !_.isNil(prediction.forecastArrivalDate) &&
      !_.isNil(prediction.forecastArrivalDate.dateLocal)
    ) {
      return this.delayPrediction.forecastArrivalDate.dateLocal;
    }
    return null;
  }

  getForecastGateDepartureDelay(): string {
    const prediction = this.delayPrediction;
    if (
      !_.isNil(prediction) &&
      !_.isNil(prediction.forecastGateDepartureDelay)
    ) {
      return '(+' + this.delayPrediction.forecastGateDepartureDelay + ' m)';
    }
    return null;
  }

  getForecastGateArrivalDelay(): string {
    const prediction = this.delayPrediction;
    if (!_.isNil(prediction) && !_.isNil(prediction.forecastGateArrivalDelay)) {
      return '(+' + this.delayPrediction.forecastGateArrivalDelay + ' m)';
    }
    return null;
  }

  getForecastDepartureColor(): string {
    const prediction = this.delayPrediction;
    if (!_.isNil(prediction) && !_.isNil(prediction.forecastDepartureColor)) {
      return this.delayPrediction.forecastDepartureColor;
    }
    return null;
  }

  getForecastArrivalColor(): string {
    const prediction = this.delayPrediction;
    if (!_.isNil(prediction) && !_.isNil(prediction.forecastDepartureColor)) {
      return this.delayPrediction.forecastDepartureColor;
    }
    return null;
  }

  /**
   * Recupera le informazioni sul progress del volo
   */
  getFlightProgressInfo(): FlightProgressInfo {
    return {
      flightDuration: this.getFlightDuration(),
      inFlightTime: this.getInFlightTime(),
      landingInTime: this.getLandingInTime()
    };
  }

  getCarouselForBag(): string {
    if (
      this.arrivingBaggageCarousel === '' ||
      this.arrivingBaggageCarousel === undefined
    ) {
      this.arrivingBaggageCarousel = '--';
    }
    return this.arrivingBaggageCarousel;
  }

  checkNullBag() {
    if (
      this.arrivingBaggageCarousel === '' ||
      this.arrivingBaggageCarousel === undefined
    ) {
      this.arrivingBaggageCarousel = ' --';
    } else {
      return this.arrivingBaggageCarousel;
    }
  }

  getFlightProgress() {
    const nowUtc = moment.utc();

    let departureUtc = moment.utc(this.departingDate.dateUtc);
    let arrivalUtc = moment.utc(this.arrivingDate.dateUtc);

    if (this.fsIdleThresh === null) {
      this.fsIdleThresh = 0;
    }

    if (this.fsDepArrThresh === null) {
      this.fsDepArrThresh = 0;
    }

    const fsLastUpdateMoreThresh = moment(this.fsLastUpdate).add(
      this.fsIdleThresh,
      'minutes'
    );
    if (nowUtc.isAfter(fsLastUpdateMoreThresh)) {
      departureUtc = departureUtc.add(this.fsDepArrThresh, 'minutes');
      arrivalUtc = arrivalUtc.add(this.fsDepArrThresh, 'minutes');
    }

    const diffNowDeparture = nowUtc.valueOf() - departureUtc.valueOf();
    let flightTime = 0;
    if (diffNowDeparture > 0) {
      flightTime = diffNowDeparture / (1000 * 60);
    }
    const aus2 = arrivalUtc.valueOf() - departureUtc.valueOf();
    let timeToArrival = 0;
    let percFlight = 0;
    if (aus2 > 0) {
      timeToArrival = aus2 / (1000 * 60);
      percFlight = (flightTime * 100) / timeToArrival;

      if (this.isLanded()) {
        percFlight = 100;
      }
    }

    if (percFlight > 100) {
      percFlight = 100;
    }

    return percFlight;
  }

  isLanded(): boolean {
    return this.status === 'L';
  }

  /**
   * Check if flight has delay
   */
  isDelayed() {
    if (this.departingDelay > 0 || this.arrivingDelay > 0) {
      return true;
    } else {
      return false;
    }
  }

  minutesConverter(minutesToConvert: number): string {
    const days: number = Math.floor(minutesToConvert / 24 / 60);
    const hours: number = Math.floor((minutesToConvert / 60) % 24);
    const minutes: number = Math.floor(minutesToConvert % 60);
    let timeConvertered: string = '';
    if (days > 0) {
      timeConvertered += days + 'd ';
    }
    if (hours > 0 || days > 0) {
      timeConvertered += hours + 'h ';
    }
    if (minutes > 0) {
      timeConvertered += minutes + 'm';
    }
    return timeConvertered;
  }
}
