import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppRoutingService } from '../app-routing.service';
import { AirportServiceModel } from '../modules/airport/models/airport-service-model';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AirlineServiceModel } from '../modules/airline/models/airline-service.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NavigateToService {
  public needBackButtonSbj: Subject<Boolean> = new Subject();
  private basePath: string = '/';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: AppRoutingService,
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.needBackButtonSbj.next(this.havePreviousUrl());
      });
  }

  /**
   * Route path dettaglio dell'aereoporto
   *
   * @param fsCode string  identificatore dell'aeroporto
   */
  public pathAirport(fsCode: string): string {
    return this.basePath + 'airport/' + fsCode;
  }

  /**
   * Navigazione al dettaglio dell'aereoporto
   *
   * @param fsCode string  identificatore dell'aeroporto
   */
  public airport(fsCode: string): void {
    this.router.navigate([this.pathAirport(fsCode)]);
  }

  /**
   * Route path dettaglio dell'aereoporto
   *
   * @param fsCode string  identificatore dell'aeroporto
   */
  public pathAirline(fsCode: string): string {
    return this.basePath + 'airline/' + fsCode;
  }

  /**
   * Navigazione al dettaglio dell'aereoporto
   *
   * @param fsCode string  identificatore dell'aeroporto
   */
  public airline(fsCode: string): void {
    this.router.navigate([this.pathAirline(fsCode)]);
  }

  /**
   * Route path timetable dell'aereoporto
   */
  public pathTimetable(): string {
    const airportCode = this.routingService.getAirportCode();
    return this.pathAirport(airportCode) + '/timetable';
  }

  /**
   * Navigazione alla timetable dell'aereoporto
   */
  public timetable(): void {
    this.router.navigate([this.pathTimetable()]);
  }

  /**
   * Route path VipLounge dell'aereoporto
   */
  public pathVipLounge(): string {
    const airportCode = this.routingService.getAirportCode();
    return this.pathAirport(airportCode) + '/vip-lounge';
  }

  /**
   * Navigazione alla VipLounge dell'aereoporto
   */
  public vipLounge(): void {
    this.router.navigate([this.pathVipLounge()]);
  }

  /**
   * Route path Dettaglio VipLounge dell'aereoporto
   */
  public pathVipLoungeDetail(loungeCode: string): string {
    return this.pathVipLounge() + '/' + loungeCode;
  }

  /**
   * Navigazione al Dettaglio VipLounge dell'aereoporto
   */
  public vipLoungeDetail(loungeCode: string): void {
    this.router.navigate([this.pathVipLoungeDetail(loungeCode)]);
  }

  /**
   * Route path Dettaglio del singolo service dell'aereoporto
   */
  public pathServiceDetail(
    service: AirportServiceModel,
    id: string | number
  ): string {
    const airportCode = this.routingService.getAirportCode();
    return (
      this.pathAirport(airportCode) + '/' + service.prettyUrl + '/detail/' + id
    );
  }

  /**
   * Navigazione al Dettaglio del singolo service dell'aereoporto
   */
  public serviceDetail(service: AirportServiceModel, id: string | number): void {
    this.router.navigate([this.pathServiceDetail(service, id)]);
  }

  public getPreviousUrl(clearHistory: boolean = true): string {
    const havePreviousUrl = this.routingService.havePreviousUrl();

    let navigateToUrl = null;
    if (havePreviousUrl) {
      console.log('@NavigateToService - getPreviousUrl: history');
      navigateToUrl = this.routingService.getPreviousUrl(clearHistory);
    } else {
      console.log('@NavigateToService - getPreviousUrl: create');
      const airportCode = this.routingService.getAirportCode();
      const airlineCode = this.routingService.getAirlineCode();
      let path: string = null;
      let service: AirportServiceModel | AirlineServiceModel = null;

      if (!_.isNil(airportCode)) {
        path = this.pathAirport(airportCode);
        service = this.routingService.getRouteData('service');
      } else if (!_.isNil(airlineCode)) {
        path = this.pathAirline(airlineCode);
        service = this.routingService.getRouteData('service');
      }

      if (service) {
        if (this.routingService.getRouteData('detail')) {
          navigateToUrl = path + '/' + service.prettyUrl;
        } else {
          navigateToUrl = path;
        }
      }

      if (navigateToUrl !== null) {
        this.routingService.clearHistory = navigateToUrl;
      }
    }
    return navigateToUrl;
  }

  public havePreviousUrl(): boolean {
    return this.getPreviousUrl(false) !== null;
  }

  public goToPreviousUrl(): void {
    const navigateToUrl = this.getPreviousUrl();
    if (navigateToUrl) {
      this.router.navigate([navigateToUrl]);
    }
  }
}
