import { Component, OnInit } from '@angular/core';
import { ErrorTypeApp } from '../../enum/enumApp';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  public errorType = ErrorTypeApp;

  constructor(private appService: AppService) {
    this.appService.setError(ErrorTypeApp.PAGE);
  }

  ngOnInit() {}
}
