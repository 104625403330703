<div class="app-footer" appDeviceType>
  <ng-container *ngIf="!isFlio()">
    <div class="footer__mobile_app">
      <a
        href="https://itunes.apple.com/it/app/safebag24-by-sostravel/id1068419699?mt=8"
        target="_blank"
      >
        <img src="./assets/img/store/apple.png" alt="Apple Store Icon"/>
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.safebag.safebag24"
        target="_blank"
      >
        <img src="./assets/img/store/android.png" alt="Play Store Icon"/>
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="isFlio()">
    <div class="footer__mobile_app">
      <a href="https://itunes.apple.com/app/id963836358?mt=8" target="_blank">
        <img src="./assets/img/store/apple.png" alt="Apple Store Icon"/>
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.getflio.flio&hl=en"
        target="_blank"
      >
        <img src="./assets/img/store/android.png" alt="Play Store Icon"/>
      </a>
    </div>
  </ng-container>
  <div class="footer__detail">
    <div class="container-custom">
      <div class="row">
        <div class="col-auto">
          <app-switch-languages [iconLanguage]="false"></app-switch-languages>
        </div>
        <div class="col-auto ml-auto  ">
          <ng-container *ngIf="isFlio()">
            <ul class="footer__social">
              <li>
                <a href="https://de-de.facebook.com/getFLIO/" target="_blank">
                  <i
                    class="etf-facebook img-responsive"
                    alt="Facebook Icon"
                  ></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/flioapp/" target="_blank">
                  <i
                    class="etf-instagram img-responsive"
                    alt="Instagram Icon"
                  ></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/getflio/" target="_blank">
                  <i class="etf-twitter img-responsive" alt="Twitter Icon"></i>
                </a>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="!isFlio()">
            <ul class="footer__social">
              <li>
                <a
                  href="https://www.facebook.com/sostravelinternational"
                  target="_blank"
                >
                  <i
                    class="etf-facebook img-responsive"
                    alt="Facebook Icon"
                  ></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/sostravel_official"
                  target="_blank"
                >
                  <i
                    class="etf-instagram img-responsive"
                    alt="Instagram Icon"
                  ></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/sostravel-com"
                  target="_blank"
                >
                  <i
                    class="etf-linkedin img-responsive"
                    alt="LinkedIn Icon"
                  ></i>
                </a>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="!isFlio()">
        <div class="row legal-row">
          <div class="col-12">
            <ul>
              <li>
                <a
                  href="http://privacy.sostravel.com/{{
                    language
                  }}_privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{ 'PRIVACY_POLICY' | translate }}</a
                >
              </li>
              <li>
                <a
                  href="https://www.iubenda.com/privacy-policy/29320991/cookie-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{ 'COOKIE_POLICY' | translate }}</a
                >
              </li>
              <li>
                <a
                  href="http://tc.sostravel.com/{{ language }}_TC.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{ 'TERMS_CONDITIONS' | translate }}</a
                >
              </li>
            </ul>
          </div>
          <div class="col-12">
            <span
            >Copyright &copy; {{ year }} sostravel.com spa. All Rights
              Reserved.</span
            >
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isFlio()">
        <div class="row legal-row">
          <div class="col-12">
            <ul>
              <li>
                <a
                  href="https://flio.aero/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{ 'PRIVACY_POLICY' | translate }}</a
                >
              </li>
              <li>
                <a
                  href="https://www.flio.com/cookie-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{ 'COOKIE_POLICY' | translate }}</a
                >
              </li>
              <li>
                <a
                  href="https://www.flio.com/terms-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >{{ 'TERMS_CONDITIONS' | translate }}</a
                >
              </li>
            </ul>
          </div>
          <div class="col-12">
            <span>© {{ year }} FLIO Limited. All rights reserved</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
