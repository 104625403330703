export const environment = {
  production: true,
  apiBaseHost: '//apipx.sostravel.com/',
  apiAirlineBaseHost: '//airlines.sostravel.com/',
  apiUrl: '//apipx.sostravel.com/ApiSafeBag24-web/webresources/safebag24/',
  sentry: {
    dsn: 'https://87beb1e9e4304909ae733ba532a80776@sentry.io/2468563',
    release: 'eta@1.4.5',
    ignoreErrors: [
      'Non-Error exception captured'
    ]
  }
};

