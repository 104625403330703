import { AirportServiceModel } from '../modules/airport/models/airport-service-model';
import { AirportTerminal } from '../modules/airport/models/AirportTerminal';
import { GenericModel } from './generic-model';

/**
 ***********************************************************************
 *
 * SOSTRAVEL.com S.p.A CONFIDENTIAL
 * __________________
 *
 * [2016] - [2018] SOSTRAVEL.com S.p.A
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of SOSTRAVEL.com S.p.A and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to SOSTRAVEL.com S.p.A
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from SOSTRAVEL.com S.p.A.
 *
 * __________________
 *
 * Secured    on (last update) :
 * Documented on (last update) :
 *
 ************************************************************************
 */
export class AirportAirlineModel extends GenericModel {
  idAirline: number;
  fs: string;
  iata: string;
  icao: string;
  name: string;
  phoneNumber: string;
  active: boolean;
  airlineLogoUrl: string;
  displayValue: string;
  searchValue: string;
  checkinOpenMinute: number;
  checkinClosedMinute: number;
  boardingOpenMinute: number;
  boardingClosedMinute: number;
  gateOpenMinute: number;
  gateClosedMinute: number;
  voteConfigModel: any;
  extraInformation: {
    airlineBaggageRulesUrl: string;
    airlineMainPhone: string;
    airlineTCUrl: string;
    airlineWebCheckInUrl: string;
    airlineWebSite: string;
    airlineName: string;
    fs: string;
  };

  constructor(jsonData: object | string) {
    super(jsonData);
  }

  // modelFromRawJson(json: any): AirportAirlineModel {
  //   this.idAirline = json['idAirline'];
  //   this.fs = json['fs'];
  //   this.iata = json['iata'];
  //   this.icao = json['icao'];
  //   this.name = json['name'];
  //   this.phoneNumber = json['phoneNumber'];
  //   this.active = json['active'];
  //   this.airlineLogoUrl = json['airlineLogoUrl'];
  //   this.displayValue = json['displayValue'];
  //   this.searchValue = json['searchValue'];
  //   this.checkinOpenMinute = json['checkinOpenMinute'];
  //   this.checkinClosedMinute = json['checkinClosedMinute'];
  //   this.boardingOpenMinute = json['boardingOpenMinute'];
  //   this.boardingClosedMinute = json['boardingClosedMinute'];
  //   this.gateOpenMinute = json['gateOpenMinute'];
  //   this.gateClosedMinute = json['gateClosedMinute'];
  //   this.voteConfigModel = json['voteConfigModel'];
  //   this.extraInformation = json['extraInformation'];
  //   // return JSON.parse(json);
  //   return this;
  // }

  // getFs() {
  //   return this.fs;
  // }

  getName(): string {
    return this.name;
  }
  getLogo(): string {
    return this.airlineLogoUrl;
  }
}
