/**
 ***********************************************************************
 *
 * SOSTRAVEL.com S.p.A CONFIDENTIAL
 * __________________
 *
 * [2016] - [2018] SOSTRAVEL.com S.p.A
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of SOSTRAVEL.com S.p.A and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to SOSTRAVEL.com S.p.A
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from SOSTRAVEL.com S.p.A.
 *
 * __________________
 *
 * Secured    on (last update) :
 * Documented on (last update) :
 *
 ************************************************************************
 */

import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppService } from './app.service';
import { ErrorTypeApp } from '../enum/enumApp';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GlobalerrorserviceService implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error: any) {
    const appService = this.injector.get(AppService);
    const router = this.injector.get(Router);

    // if (environment.production === false) {
    console.error('Error:', error);
    // }

    if (error.status === 404) {
      appService.setError(ErrorTypeApp.LINK);
      // } else if (error.status === 404) {
      //   console.log('error 404');
      //   appService.setError(ErrorTypeApp.SERVICE);
    } else {
      if (router.url === '/') {
        appService.setLoading(false);
        appService.setError(ErrorTypeApp.PAGE);
      }
    }
  }
}
