import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TruncatePipe } from 'src/app/pipes/truncate.pipe';
import { DateTimePipe } from 'src/app/pipes/date-time.pipe';
import { TerminalGateComponent } from 'src/app/components/terminal-gate/terminal-gate.component';
import {
  MatBottomSheetModule,
  MatCardModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule
} from '@angular/material';
import { FiltersComponent } from './components/filters/filters.component';
import { ServiceItemComponent } from './components/service-item/service-item.component';
import { ServiceFilterEmptyComponent } from './components/service-filter-empty/service-filter-empty.component';
import { HighlightSearch } from '../../pipes/highlight.pipe';
import { EmptySectionComponent } from './components/empty-section/empty-section.component';
import { UiImageLoaderDirective } from './directives/ui-image-loader.directive';
import { DynamicComponentLoaderDirective } from './directives/dynamic-component-loader.directive';
import { ScrollToService } from './services/scroll-to.service';
import { FilterChipsComponent } from './components/filters/chips/filter-chips.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { DeviceTypeDirective } from './directives/device-type.directive';
import { EmptySearchComponent } from '../../components/empty-search/empty-search.component';
import { DialogFilterListComponent } from '../../components/dialog-filter-list/dialog-filter-list.component';

@NgModule({
  declarations: [
    TruncatePipe,
    DateTimePipe,
    TerminalGateComponent,
    FiltersComponent,
    FilterChipsComponent,
    ServiceItemComponent,
    ServiceFilterEmptyComponent,
    HighlightSearch,
    EmptySectionComponent,
    UiImageLoaderDirective,
    DynamicComponentLoaderDirective,
    DeviceTypeDirective,
    EmptySearchComponent,
  ],
  imports: [CommonModule, TranslateModule, FormsModule, MatMenuModule, DeviceDetectorModule.forRoot()],
  exports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    TruncatePipe,
    DateTimePipe,
    TerminalGateComponent,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatCardModule,
    FiltersComponent,
    FilterChipsComponent,
    ServiceItemComponent,
    ServiceFilterEmptyComponent,
    HighlightSearch,
    EmptySectionComponent,
    UiImageLoaderDirective,
    DynamicComponentLoaderDirective,
    DeviceTypeDirective,
    DeviceDetectorModule,
    EmptySearchComponent,
  ],
  providers: [ScrollToService]
})
export class SharedModule {
}
