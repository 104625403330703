import * as _ from 'lodash';
import { BehaviorSubject, noop } from 'rxjs';
import { AfterViewChecked, Component, EventEmitter, HostListener, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SubscribeService } from 'src/app/services/subscribe.service';
import { ScrollToService } from '../../../modules/shared/services/scroll-to.service';

export interface IMainStylesRule {
  top: number;
  left: number;
  height: number;
  width: number;
  innerHeight: number;
  innerWidth: number;
  padding: {
    top: number;
    right: number;
    bottom: number;
    left: number;
    x: number;
    y: number;
  };
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
    x: number;
    y: number;
  };
}

export interface IStepTutorial {
  name: string;
  title: string;
  description: string;
  arrow?: string;
  elevateSelector?: string;
  scroll?: boolean;
  spot?: boolean;
}

@Component({
  selector: 'app-tutorial',
  animations: [
    trigger('fadeInOut', [
      state(
        'show',
        style({
          opacity: 1
        })
      ),
      state(
        'hide',
        style({
          opacity: 0
        })
      ),
      transition('hide => show', [animate('.3s')]),
      transition('show => hide', [animate('.3s')])
    ])
  ],
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TutorialComponent implements AfterViewChecked, OnDestroy {
  @Input() initLatency: number = 1500;
  @Input() cookieName: string = '';
  @Input() stepsTutorial: IStepTutorial[] = [];
  public animateContent: boolean = false;
  public animateStep: boolean = false;
  public event: EventEmitter<boolean> = new EventEmitter();
  public contentStyles: object = {};
  public spotStyles: object = {};
  public stepIndexSbj: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  private tutorialInitialized: boolean = false;
  private resize: boolean = false;
  private alreadyShow: boolean = true;
  private document: Document = window.document;
  private animationCompleteCallback: any = noop();
  private readonly classToBody: string = 'tutorial';
  private animationClose: boolean = false;

  constructor(
    private subscribeService: SubscribeService,
    private scrollToService: ScrollToService
  ) {
    /**
     * Azioni che vengono effettuate ogni volta che si cambia lo step del tutorial
     */
    this.subscribeService.addSubscribe(
      this.stepIndexSbj.subscribe(v => {
        if (v !== null) {
          this.scrollToElement();
        }
      })
    );
    /**
     * Azioni che vengono effettuate ogni volta che termina lo scroll di uno step del tutorial
     */
    this.subscribeService.addSubscribe(
      this.event.subscribe(v => {
        if (v !== null) {
          // console.log('Evento scroll complete!');
          this.cloneElement();
          this.setStylesFromTarget();
          this.animateContent = true;
          // attende l'animazione principale e poi anima il singolo steps
          setTimeout(() => {
            this.animateStep = true;
          }, 200);
        }
      })
    );
  }

  get activeStep(): IStepTutorial {
    return this.stepsTutorial[this.stepIndex];
  }

  /**
   * Ritorna l'indice dello step attivo
   */
  get stepIndex() {
    return this.stepIndexSbj.value;
  }

  /**
   * Ritorna la variabile che permette di capire se visualizzare o meno il tutorial
   */
  get canShow() {
    return !this.alreadyShow;
  }

  get contentElement(): Element {
    return this.getContentElement(this.activeStep);
  }

  get spotElement(): Element {
    return this.getSpotElement(this.activeStep);
  }

  /**
   * Ritorna l'elemento che contiene gli step
   */
  get containerElement(): Element {
    return this.document.querySelector('.tutorials');
  }

  /**
   * Ritorna l'elemento da evidenziare nello step attuale
   */
  public targetElement(): Element {
    if (!_.isNil(this.activeStep) && !_.isNil(this.activeStep.elevateSelector)) {
      const element = this.document.querySelector(
        this.activeStep.elevateSelector
      );
      if (_.isNil(element)) {
        console.error('ATTENZIONE - L\'elemento [elevateSelector] non esiste!');
      }
      return element;
    }
  }

  ngOnDestroy() {
    this.subscribeService.unSubscribe();
  }

  /**
   * Quando viene modificata la grandezza della pagina vengono modificate le posizioni delle scritte e dello spot
   */
  @HostListener('window:resize')
  onResize() {
    if (this.tutorialInitialized) {
      this.resize = true;
      this.setStylesFromTarget();
      this.scrollToElement();
    }
  }

  /**
   * Verifica che tutti gli elementi 'target' per ogni step siano nel DOM
   * altrimenti fa attendere l'inizializzazione del tutorial
   */
  allElementsExist(): boolean {
    let elementsFounded = true;
    for (const step of this.stepsTutorial) {
      if (step.elevateSelector) {
        const element = this.document.querySelector(step.elevateSelector);
        const content = this.getContentElement(step);
        const spot = this.getSpotElement(step);
        if (_.isNil(element) || _.isNil(content) || _.isNil(spot)) {
          elementsFounded = false;
        }
      }
    }
    return elementsFounded;
  }

  ngAfterViewChecked(): void {
    if (this.tutorialInitialized === false) {
      this.initTutorial();
    }
  }

  /**
   * Inizializza il tutorial
   */
  initTutorial(): void {
    /**
     * Timer in secondi che si avvia al caricamento della pagina per posticipare la visualizzazione del tutorial
     */
    if (this.tutorialInitialized === false && this.allElementsExist()) {
      this.alreadyShow = this.hasCookie(this.cookieName);
      if (this.alreadyShow === false) {
        this.tutorialInitialized = true;
        setTimeout(() => {
          this.document.body.classList.add(this.classToBody);
          this.createCookie(this.cookieName);
          this.stepIndexSbj.next(0);
        }, this.initLatency);
      } else {
        this.tutorialInitialized = true;
      }
    }
  }

  /**
   * Calcola e imposta gli stili al container e allo spot (se esiste)
   */
  public setStylesFromTarget(): void {
    // const tutorialBounding = this.containerElement.getBoundingClientRect();
    if (!_.isNil(this.targetElement())) {
      const targetRule = this.getMainStyleRule(this.targetElement());
      // const elevateBounding = this.targetElement.getBoundingClientRect();
      // const elevateStyles = window.getComputedStyle(this.targetElement);
      const top = targetRule.top + targetRule.height / 2;
      const width = targetRule.width - targetRule.padding.left;
      const left = targetRule.left + width / 2 + targetRule.padding.left;
      const height = targetRule.height;
      this.contentStyles = {
        top: targetRule.top + 'px'
        // left: elevateBounding.left + 'px',
        // right: (window.innerWidth - (tutorialBounding.left + tutorialBounding.width)) + 'px'
      };
      if (this.activeStep.spot) {
        this.spotStyles = {
          visibility: 'visible',
          top: top + 'px',
          left: left + 'px',
          minWidth: width + 'px',
          minHeight: height + 'px'
        };
        // this.cloneElement();
      }
    }
  }

  /**
   * Clona l'elemento da evidenziare e lo inserisce nello spot
   */
  public cloneElement() {
    // fix DOM latency
    setTimeout(() => {
      if (this.activeStep &&
        !_.isNil(this.activeStep)) {
        const spot = this.spotElement;
        if (
          this.activeStep.spot &&
          spot !== null &&
          spot.children.length === 0 &&
          !_.isNil(this.targetElement())
        ) {
          const styles = window.getComputedStyle(this.targetElement());
          const clone: HTMLElement = this.targetElement().cloneNode(
            true
          ) as HTMLElement;
          clone.classList.value = ''; // rimozione di tutte le classi per non avere interferenze
          for (const key of ['height', 'font-size', 'font-weight', 'color']) {
            clone.style[key] = styles[key];
          }
          clone.style.width =
            parseFloat(styles.width) -
            (parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight)) +
            'px';
          spot.appendChild(clone);
        }
      }
    });
  }

  /**
   * Assegnamento di stepsTutorial (array di tipo IStep contenente tutti gli step del tutorial)
   *
   * @param tutorial - JSON contenente l'array di tipo IStep
   */
  public setTutorial(tutorial: IStepTutorial[]): void {
    this.stepsTutorial = tutorial;
  }

  public animationCloseTutorial(event) {
    if (event.toState === 'hide' && this.animationClose) {
      this.alreadyShow = true;
      this.scrollToService.scrollTo(0);
    } else if (event.toState === 'hide') {
      this.animationClose = true;
    }
  }

  /**
   * Funzione che si attiva al completamento dell'animazione sui contenuti
   *
   * @param event - Evento al completamento dell'animazione sui contenuti
   */
  public animationComplete(event) {
    if (event.toState === 'hide' && typeof this.animationCompleteCallback === 'function') {
      this.animationCompleteCallback();
    }
  }

  /**
   * Viene modificato lo step attivo, gli viene impostato quello precedente
   */
  public prevTutorial(): void {
    this.performChangeStep(this.stepIndex - 1);
  }

  /**
   * Viene modificato lo step attivo, gli viene impostato quello successivo
   */
  public nextTutorial(): void {
    this.performChangeStep(this.stepIndex + 1);
  }

  /**
   * Chiusura del tutorial
   */
  public closeTutorial(): void {
    this.animateContent = false;
    this.document.body.classList.remove(this.classToBody);
    this.subscribeService.unSubscribe();
  }

  /**
   * Ritorna lo step attuale da visualizzare all'interno del template
   *
   * @param index - Indice dello step, viene verificato se è quello attivo
   */
  public showStep(index: number) {
    return index === this.stepIndex;
  }

  /**
   * Controllo sulla presenza dell'immagine della freccia all'interno dello step
   */
  showImage() {
    return this.activeStep && this.activeStep.arrow !== '';
  }

  /**
   * X nel template
   */
  public showExit(): boolean {
    return this.stepsTutorial.length === 1;
  }

  /**
   * Freccia destra e del tasto Skip nel template
   */
  public showSkipNext(): boolean {
    return this.stepsTutorial.length !== this.stepIndex + 1;
  }

  /**
   * Freccia sinistra
   */
  public showPrev(): boolean {
    return this.stepIndex !== 0;
  }

  /***************************************************************************************************
   * Controlli sui pulsanti da visualizzare all'interno del template del tutorial
   */

  /**********************************************************
   * Casi di tutorial con singolo step
   */

  /**
   * Tasto Finish
   */
  public showFinish(): boolean {
    return (
      this.stepsTutorial.length === this.stepIndex + 1 &&
      this.stepsTutorial.length !== 1
    );
  }

  /**********************************************************
   * Casi di tutorial con molteplici step
   */

  getMainStyleRule(element: Element): IMainStylesRule {
    // if(!_.isNil(element)){
    //
    // }
    const bounding = element.getBoundingClientRect();
    const styles = window.getComputedStyle(element);

    const rules: IMainStylesRule = {
      top: bounding.top,
      left: bounding.left,
      height: parseFloat(styles.getPropertyValue('height')),
      width: parseFloat(styles.getPropertyValue('width')),
      innerHeight: bounding.height,
      innerWidth: bounding.width,
      padding: {
        top: parseFloat(styles.getPropertyValue('padding-top')),
        right: parseFloat(styles.getPropertyValue('padding-right')),
        bottom: parseFloat(styles.getPropertyValue('padding-bottom')),
        left: parseFloat(styles.getPropertyValue('padding-left')),
        x: 0,
        y: 0,
      },
      margin: {
        top: parseFloat(styles.getPropertyValue('margin-top')),
        right: parseFloat(styles.getPropertyValue('margin-right')),
        bottom: parseFloat(styles.getPropertyValue('margin-bottom')),
        left: parseFloat(styles.getPropertyValue('margin-left')),
        x: 0,
        y: 0,
      },
    };

    rules.padding.x = rules.padding.right + rules.padding.left;
    rules.padding.y = rules.padding.top + rules.padding.bottom;

    rules.margin.x = rules.margin.right + rules.margin.left;
    rules.margin.y = rules.margin.top + rules.margin.bottom;

    // rules.outerHeight = rules.height + rules.padding.y + rules.margin.y;
    // rules.outerWidth = rules.width + rules.padding.x + rules.margin.x;

    return rules;
  }

  private getContentElement(step: IStepTutorial) {
    return this.document.querySelector('.tutorial__step--' + step.name + ' .tutorial__content');
  }

  private getSpotElement(step: IStepTutorial) {
    return this.document.querySelector('.tutorial__step--' + step.name + ' .tutorial__spot');
  }

  /**
   * Funzione che si attiva al cambio di step nascondendo lo step precedentemente attivo
   */
  private performChangeStep(newStepIndex: number) {
    this.animateStep = false;
    this.resize = false;
    this.animationCompleteCallback = () => {
      this.stepIndexSbj.next(newStepIndex);
      this.animationCompleteCallback = noop();
    };
  }

  /**
   * Scrolla la pagina fino a centrarla rispetto all'elemento da evidenziare nello step
   */
  private scrollToElement() {
    if (!_.isNil(this.activeStep) && !_.isNil(this.activeStep.scroll)) {
      setTimeout(() => {
        if (!_.isNil(this.targetElement())) {
          const targetRule = this.getMainStyleRule(this.targetElement());
          const contentRule = this.getMainStyleRule(this.contentElement);
          const elevateTopPosition = targetRule.top + window.scrollY;
          const windowHalfHeight = window.innerHeight / 2;

          let offset = 0;
          let calcTop = (elevateTopPosition - windowHalfHeight) + targetRule.height / 2;
          if (windowHalfHeight < contentRule.height) {
            offset = Math.abs(windowHalfHeight - contentRule.height);
            offset += targetRule.height;
          }
          calcTop -= offset;

          this.scrollToService.scrollTo(calcTop, {
            callback: () => {
              this.event.next(true);
            }
          });
        }
      });
    } else {
      this.event.next(true);
    }
  }

  /**
   * Verifica l'esistenza di un cookie, ricevendo come parametro il nome di esso
   *
   * @param name - Nome del cookie di cui verificare l'esistenza
   */
  private getCookie(name: string) {
    const dc = this.document.cookie;
    const prefix = name + '=';
    let begin = dc.indexOf('; ' + prefix);
    let end: number;
    if (begin === -1) {
      begin = dc.indexOf(prefix);
      if (begin !== 0) {
        return null;
      }
    } else {
      begin += 2;
      this.document.cookie.indexOf(';', begin);
      if (end === -1) {
        end = dc.length;
      }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  /**
   * Genera un cookie ricevendo come parametro le informazioni desiderate
   *
   * @param cname - Nome del cookie
   * @param cvalue - Valore del cookie
   * @param exdays - Numero di giorni di durata del cookie
   */
  private setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    this.document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  /**
   * Crea un cookie, se già non esistente, richiamando la funzione setCookie e passandogli le informazioni desiderate
   *
   * @param cookieName - Nome del cookie da creare nel caso in cui non sia già presente
   */
  private createCookie(cookieName: string) {
    if (!this.getCookie(cookieName)) {
      this.setCookie(cookieName, 'showed', 365);
    }
  }

  /**
   * Dato il nome di un cookie, restituisce in base all'esistenza di esso, true o false.
   * Nel caso specifico: true - visualizzare tutorial, false - non visualizzare
   *
   * @param cookieName - Nome del cookie di cui verificare l'esistenza
   */
  private hasCookie(cookieName: string): boolean {
    return !_.isNil(this.getCookie(cookieName));
  }
}
